const AdminReducer = (state, action) => {
  const { type } = action
  switch (type) {
    case "score":
      state[action.payload.region][action.payload.seed].score[
        action.payload.round
      ] = parseInt(action.payload.score)
      return { ...state }
    case "seed":
      state[action.payload.region][action.payload.seed].team =
        action.payload.team
      return { ...state }
    default:
      return state
  }
}

export default AdminReducer
