import Styled from "styled-components"
import Img from "gatsby-image"

const BracketContainer = Styled.section`
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 24px;
    font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: ${({ theme }) => theme.Black};
    font-family: Geogrotesque, Rockwell, Georgia, Times, Times New Roman, serif;
    width: 100%;
    max-width: 100%;
    margin-bottom: 30px;
    position: relative;
    @media print {
        width: 70%;
        max-width: 70%;
        position: absolute;
        margin-top: -200px;
        left: 0;
        top: 0;
    }
`
const InnerContainer = Styled.div`
    width: 1220px;
    margin: 0 auto;
`
const TitleContainer = Styled.div`
    text-transform: uppercase;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px;
`
const TitleHolder = Styled.div`
    border-top: 10px solid #edeef0;
    width: ${({ width }) => `${width}%`}
`
const Title = Styled.h4`
    font-size: 12px;
    font-size: 0.65rem;
    line-height: 15px;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 10px;
    text-align: ${({ align }) => align};
`
const BracketLogoHolder = Styled.div`
    width: 100%;
    text-align: center;
`
const Image = Styled(Img)`
    height: 120px;
    width: ${({ width }) => (width ? `${width}px` : "auto")};
`
const Bracket = Styled.div`
    box-sizing: border-box;
    overflow: inherit;
    touch-action: pan-y pan-x pinch-zoom;
    position: relative;
    min-width: 768px;
    background-color: #f6f8fa;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: auto;
`
const Regions = Styled.div`
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
`
const Region = Styled.div`
    width: 50%;
    position: relative;
    &:nth-child(2n) {
        text-align: right;
    }
    height: 920px;
`
const Round = Styled.div`
    background-color: #fff;
`
const PlayPod = Styled.div`
    display: table;
    position: absolute;
    overflow: hidden;
    width: 218px;
    height: 91px;
    font-size: 0.75em;
    background-color: #fff;
    border: 1px solid #d8d8d8;
    z-index: 99;
    text-align: left;
    left: ${({ left }) => `${left}px`};
    right: ${({ right }) => `${right}px`};
    top: ${({ top }) => `${top}px`};
    bottom: ${({ bottom }) => `${bottom}px`}
`
const PlayPodCenter = Styled.div`
    display: table;
    position: absolute;
    overflow: hidden;
    width: 218px;
    height: 91px;
    font-size: 0.75em;
    background-color: #fff;
    border: 1px solid #d8d8d8;
    z-index: 99;
    text-align: left;
    margin: auto;
    position: relative;
    left: 0;
    right: 0;
`
const PodStatus = Styled.div`
    width: 215px;
    display: table;
    height: 25px;
    padding: 0 4px;
    border-bottom: 1px solid #dbdbdb;
    span {
        display: block;
        color: #7d7f81;
        font-size: 0.8rem;
        margin-top: 2px;
        margin-left: 4px;
        font-weight: 500;
    }
    h4 {
        color: #000;
        display: none;
    }
`
const PodState = Styled.span`
    ;
`
const Teams = Styled.div`
     padding: 5px 5px 0;
`
const Team = Styled.div`
    text-align: ${({ align }) => (align ? align : "left")};
    display: table;
    min-height: 33px;
    padding: 2px 3px;
    font-size: 1.1em;
`
const TeamSpan = Styled.span`
    display: table-cell;
    vertical-align: middle;
    padding: 3px;
    opacity: 0.4;
`
const Seed = Styled(TeamSpan)`
    color: ${({ theme }) => theme.Red};
    font-size: 0.9em;
    width: 12px;
    text-align: right;
`
const Name = Styled(TeamSpan)`
    width: 200px;
    font-size: 0.8rem;
    padding-left: 0;
`
const Score = Styled(TeamSpan)`
    width: 10px;
`
const Check = Styled(TeamSpan)`
    width: 15px;
`
const RegionName = Styled.h3`
    margin-top: -10px;
`
const BracketLines = Styled.div`
    position: absolute;
    width: 45px;
    height: ${({ height }) => (height ? `${height}px` : `100px`)};
    z-index: 8;
    left: ${({ left }) => (left ? `${left}px` : "unset")};
    right: ${({ right }) => (right ? `${right}px` : "unset")};
    top: ${({ top }) => (top ? `${top}px` : "unset")};
    bottom: ${({ bottom }) => (bottom ? `${bottom}px` : "unset")};
    overflow: hidden;
`
const BracketLinesFlip = Styled(BracketLines)`
    transform: scaleY(-1);
    filter: FlipV;
`
const FirstFour = Styled.div`
    background-color: #edeef0;
    height: 200px;
    position: relative;
`
const FFName = Styled.div`
    width: 100%;
    text-align: center;
    padding-top: 80px;
`
const FFGame = Styled.div`
    width: 205px;
    position: absolute;
    top: 0;
    text-align: center;
    padding-top: 25px;
    left: ${({ left }) => (left ? `${left}px` : "unset")};
    right: ${({ right }) => (right ? `${right}px` : "unset")};
`
const CenterFinal = Styled.section`
    height: 280px;
    @media print {
        margin-bottom: 300px;
    }
`
const CenterFinalRound = Styled.div`
    position: relative;
    width: 100%;
    text-transform: capitalize;
    h3 {
        margin-bottom: 5px;
        text-transform: uppercase;
    }
`
const ChampRoundTitle = Styled.div`
    position: absolute;
    text-align: center;
    width: 200px;
    top: -13px;
    h3 {
        font-weight: 500;
    }
    left: ${({ left }) => (left ? `${left}px` : "unset")};
    right: ${({ right }) => (right ? `${right}px` : "unset")};
`
const ChampTitle = Styled.div`
    width: 100%;
    text-align: center;
`
const CenterFinalGames = Styled.div`
    position: relative;
    margin-top: 10px;
    height: 90px;
`
const CenterFinalButtonContainer = Styled.div`
    position: relative;
    width: 100%;
    margin-top: 35px;
    text-align: center
`
const SVG = Styled.svg`
    width: 45px;
    height: 750px;
`
const G = Styled.g`
    transform: ${({ transform }) => transform};
    stroke: ${({ theme }) => theme.BracketStroke};
    stroke-width: ${({ theme }) => theme.BracketStrokeWidth};
`
export {
  BracketContainer,
  InnerContainer,
  TitleContainer,
  TitleHolder,
  Title,
  BracketLogoHolder,
  Image,
  Bracket,
  Regions,
  Region,
  Round,
  PlayPod,
  PlayPodCenter,
  PodStatus,
  PodState,
  Teams,
  Team,
  TeamSpan,
  Seed,
  Name,
  Score,
  Check,
  RegionName,
  BracketLines,
  BracketLinesFlip,
  FirstFour,
  FFName,
  FFGame,
  CenterFinal,
  CenterFinalRound,
  ChampRoundTitle,
  ChampTitle,
  CenterFinalGames,
  CenterFinalButtonContainer,
  SVG,
  G,
}
