// winner = false
// loser = true
const Reducer = (state, action) => {
  const { type } = action
  const { winner, loser } = action.payload
  switch (type) {
    case "refresh":
      return action.payload
    case "isElimOne":
      // fill remainder & current
      state[action.payload.region][loser][type] = true
      state[action.payload.region][loser].isElimTwo = true
      state[action.payload.region][loser].isElimThree = true
      state[action.payload.region][loser].isElimFour = true
      state[action.payload.region][loser].isElimFive = true
      state[action.payload.region][loser].isElimSix = true
      // fill remainder & current
      state[action.payload.region][winner][type] = false
      state[action.payload.region][winner].isElimTwo = false
      state[action.payload.region][winner].isElimThree = false
      state[action.payload.region][winner].isElimFour = false
      state[action.payload.region][winner].isElimFive = false
      state[action.payload.region][winner].isElimSix = false
      return { ...state }
    case "isElimTwo":
      // fill remainder & current
      state[action.payload.region][loser][type] = true
      state[action.payload.region][loser].isElimThree = true
      state[action.payload.region][loser].isElimFour = true
      state[action.payload.region][loser].isElimFive = true
      state[action.payload.region][loser].isElimSix = true
      // fill remainder & current
      state[action.payload.region][winner][type] = false
      state[action.payload.region][winner].isElimThree = false
      state[action.payload.region][winner].isElimFour = false
      state[action.payload.region][winner].isElimFive = false
      state[action.payload.region][winner].isElimSix = false
      return { ...state }
    case "isElimThree":
      // fill remainder & current
      state[action.payload.region][loser][type] = true
      state[action.payload.region][loser].isElimFour = true
      state[action.payload.region][loser].isElimFive = true
      state[action.payload.region][loser].isElimSix = true
      // fill remainder & current
      state[action.payload.region][winner][type] = false
      state[action.payload.region][winner].isElimFour = false
      state[action.payload.region][winner].isElimFive = false
      state[action.payload.region][winner].isElimSix = false
      return { ...state }
    case "isElimFour":
      // fill remainder & current
      state[action.payload.region][loser][type] = true
      state[action.payload.region][loser].isElimFive = true
      state[action.payload.region][loser].isElimSix = true
      // fill remainder & current
      state[action.payload.region][winner][type] = false
      state[action.payload.region][winner].isElimFive = false
      state[action.payload.region][winner].isElimSix = false
      return { ...state }
    case "isElimFive":
      // fill remainder & current
      state[action.payload.regionLose][loser][type] = true
      state[action.payload.regionLose][loser].isElimSix = true
      // fill remainder & current
      state[action.payload.regionWin][winner][type] = false
      state[action.payload.regionWin][winner].isElimSix = false
      return { ...state }
    case "isElimSix":
      state[action.payload.regionLose][loser][type] = true
      state[action.payload.regionWin][winner][type] = false
      return { ...state }
    default:
      return state
  }
}

export default Reducer
