import React from "react"
import Styled from "styled-components"

const Label = Styled.div`
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    input {
        opacity: 0;
    }
`
const Checkmark = Styled.span` 
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: ${({ value, theme }) =>
      value ? theme.Red : theme.Black};
    &:after {
        content: "";
        position: absolute;
        display: block;
        top: 4px;
        left: 4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #fff;
    }
    &:hover {
        background-color: ${({ theme }) => theme.Red};
    }
`

const RadioSelect = ({ name, value, handleRadioChange, disabled }) => (
  <Label>
    <input
      disabled={disabled}
      type="radio"
      name={name}
      value={value}
      defaultChecked={value}
    />
    <Checkmark value={value} onClick={disabled ? null : handleRadioChange} />
  </Label>
)

export default RadioSelect
