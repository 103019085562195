import React, { useEffect, useState, Suspense, useReducer } from "react"
import * as actions from "../../redux/actions"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import Img from "gatsby-image"
import Styled from "styled-components"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Bracket from "../../components/Bracket"
import Button from "../../components/Button"
import { BallTriangle as Loading } from "../../components/Common/loaders"
import { Form } from "../../components/Form/StyledParts"
import { Danger as Err, Success, Info } from "../../components/Form/Alerts"
import {
  ClientPrintProvider,
  ClientNoPrint,
  ClientPrint,
} from "../../components/ClientPrint"

import { SEEDS, ROUNDS } from "../../config"

import AdminErrorReducer from "./AdminErrorReducer"
import AdminReducer from "./AdminReducer"

const Title = Styled.div`
    text-align: center;
    h1 {
        font-family: ${({ theme }) => theme.fontPrimary};
    }
`
const InfoContainer = Styled.div`
    display: flex;
    flex-direction: column;
    button {
        margin-bottom: 10px;
    }
    h3 {
        font-family: ${({ theme }) => theme.fontSecondary};
        font-weight: bold;
        span {
            font-weight: lighter;
            font-style: italic;
        }
    }
`
const BgImage = Styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: ${({ height }) => (height ? height : "auto")};

  & > img {
    object-fit: ${({ fit }) =>
      fit ? `${fit} !important` : "cover !important"};
    object-position: ${({ position }) =>
      position ? `${position} !important` : "50% 50% !important"};
    font-family: ${({ fit }) =>
      fit ? `object-fit: ${fit} !important` : `object-fit: cover !important`};
    object-position: ${({ position }) =>
      position ? `${position} !important` : "50% 50% !important"};
  }
`

const appendSuffix = n => {
  const x = n % 10
  const y = n % 100
  if (x === 1 && y !== 11) {
    return `${n}st`
  }
  if (x === 2 && y !== 12) {
    return `${n}nd`
  }
  if (x === 3 && y !== 13) {
    return `${n}rd`
  }
  return `${n}th`
}
const AdminInitalState = {
  seed: {
    region: "",
    seed: "",
    team: "",
    submit: "",
  },
  score: { region: "", seed: "", round: "", score: "", submit: "" },
}
const DashboardPage = ({
  UpdateBracketState,
  UpdateAdminBracket,
  bracket,
  user,
  bracketSuccess,
  loading,
  authenticated,
  GetUsersCsv,
  Logout,
  csvSuccess,
  csvError,
  updateSuccess,
  updateError,
  data,
}) => {
  // Server Side Render
  const isSSR = typeof window === "undefined"
  // admin controls
  const [state, dispatch] = useReducer(AdminReducer, bracket)
  const [adminError, dispathAdminError] = useReducer(
    AdminErrorReducer,
    AdminInitalState
  )
  const [notify, setNotify] = useState("")
  const [seed, setSeed] = useState(false)
  const [cnt, setCnt] = useState(0)
  const [adminSeedSuccess, setAdminSeedSuccess] = useState("")
  const [adminScoreSuccess, setAdminScoreSuccess] = useState("")
  const { register, handleSubmit } = useForm()

  useEffect(() => {
    let mounted = true
    if (mounted && !authenticated) {
      Logout()
    }
    return () => {
      mounted = false
    }
  }, [])
  const seedUpdate = async data => {
    try {
      const { region, seed, team } = data
      const errors = {}
      if (!region) {
        errors.region = "Region is required"
        dispathAdminError({
          type: "seed",
          payload: { target: "region", error: errors.region },
        })
      }
      if (!seed) {
        errors.seed = "Seed is required"
        dispathAdminError({
          type: "seed",
          payload: { target: "seed", error: errors.seed },
        })
      }
      if (!team) {
        errors.team = "Team is required"
        dispathAdminError({
          type: "seed",
          payload: { target: "team", error: errors.team },
        })
      }
      if (Object.keys(errors).length === 0) {
        //! Update Team by Seed within State
        dispatch({ type: "seed", payload: { region, seed, team } })
        UpdateBracketState(state)
        setNotify("You need to submit for changes to take affect")
        setAdminSeedSuccess("Seed Updated!")
        setCnt(cnt => cnt + 1)
      } else {
        dispathAdminError({
          type: "seed",
          payload: { target: "submit", error: "Fix Seed Update Errors" },
        })
      }
    } catch (error) {
      console.log(`seedUpdate error: ${error}`)
      dispathAdminError({
        type: "seed",
        payload: { target: "submit", error: "Unknown error occured" },
      })
    }
  }
  const scoreUpdate = async data => {
    try {
      const { region, seed, round, score } = data
      const errors = {}
      if (!region) {
        errors.region = "Region is required"
        dispathAdminError({
          type: "score",
          payload: { target: "region", error: errors.region },
        })
      }
      if (!seed) {
        errors.seed = "Seed is required"
        dispathAdminError({
          type: "score",
          payload: { target: "seed", error: errors.seed },
        })
      }
      if (!round) {
        errors.round = "Round is required"
        dispathAdminError({
          type: "score",
          payload: { target: "round", error: errors.round },
        })
      }
      if (!score) {
        errors.score = "Score is required"
        dispathAdminError({
          type: "score",
          payload: { target: "score", error: errors.score },
        })
      }
      if (Object.keys(errors).length === 0) {
        //! Update Score within State
        dispatch({ type: "score", payload: { region, seed, round, score } })
        UpdateBracketState(state)
        setNotify("You need to submit for changes to take affect")
        setAdminScoreSuccess("Score Updated!")
        setCnt(cnt => cnt + 1)
      } else {
        dispathAdminError({
          type: "score",
          payload: { target: "submit", error: "Fix Score Update Errors" },
        })
      }
    } catch (error) {
      console.log(`scoreUpdate error: ${error}`)
      dispathAdminError({
        type: "score",
        payload: { target: "submit", error: "Unknown error occured" },
      })
    }
  }
  const handleBracketUpdate = () => {
    // submit from redux
    UpdateAdminBracket(bracket)
    setNotify("")
    setAdminSeedSuccess("")
    setAdminScoreSuccess("")
  }
  const handleBracketPrint = () => {
    if (!isSSR) {
      window.print()
    }
  }
  const renderDashboardTitle = () => {
    return (
      <div className="row my-3">
        <div className="col-12 col-md-8 d-flex flex-column mr-auto ml-auto">
          <Title>
            <h1>Dixie Bracket Dashboard</h1>
          </Title>
        </div>
        <div className="col-12 pb-3" />
      </div>
    )
  }
  const renderUserInfo = () => {
    if (user) {
      return (
        <div className="row my-3">
          <div className="col-12 col-md-8 mr-auto ml-auto d-flex flex-column align-items-center">
            <InfoContainer>
              <h3 className="text-center">
                User:
                <br />
                <span>{user.UserName}</span>
              </h3>
              <h3 className="text-center">
                Email:
                <br />
                <span>{user.UserEmail}</span>
              </h3>
              {user.IsAdmin && (
                <h3 className="text-center my-3">
                  Admin Bracket
                  <br />
                  <span>
                    Your changes calculate all bracket scores and ranks
                  </span>
                </h3>
              )}
            </InfoContainer>
          </div>
        </div>
      )
    }
  }
  const renderForm = () => {
    if (seed) {
      return (
        <>
          <div className="col-12 text-center">
            <h2>Set Inital Seeds</h2>
          </div>
          <div className="col-12 my-3">
            <Form onSubmit={handleSubmit(seedUpdate)}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-4">
                    <select name="region" ref={register()} defaultValue="">
                      <option value="" disabled>
                        Region
                      </option>
                      <option value="east">East</option>
                      <option value="south">South</option>
                      <option value="west">West</option>
                      <option value="midwest">MidWest</option>
                    </select>
                    {adminError.seed.region && (
                      <Err>{adminError.seed.region}</Err>
                    )}
                  </div>
                  <div className="col-4">
                    <select name="seed" ref={register()} defaultValue="">
                      <option value="" disabled>
                        Seed
                      </option>
                      {SEEDS.map(seed => (
                        <option key={`seed-${seed}`} value={seed}>
                          {seed}
                        </option>
                      ))}
                    </select>
                    {adminError.seed.seed && <Err>{adminError.seed.seed}</Err>}
                  </div>
                  <div className="col-4">
                    <input
                      type="text"
                      name="team"
                      placeholder="Team"
                      ref={register()}
                    />
                    {adminError.seed.team && <Err>{adminError.seed.team}</Err>}
                  </div>
                  <div className="col-12 text-center">
                    <Button onClick={handleSubmit(seedUpdate)}>
                      Update Initial Seed
                    </Button>
                    {adminError.seed.submit && (
                      <>
                        <br />
                        <Err>{adminError.seed.submit}</Err>
                      </>
                    )}
                    {adminSeedSuccess && (
                      <>
                        <br />
                        <Success>{adminSeedSuccess}</Success>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="col-12 text-center">
            <h2>Set Team Scores</h2>
          </div>
          <div className="col-12 my-3">
            <Form onSubmit={handleSubmit(scoreUpdate)}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-3">
                    <select name="region" ref={register()} defaultValue="">
                      <option value="" disabled>
                        Region
                      </option>
                      <option value="east">East</option>
                      <option value="south">South</option>
                      <option value="west">West</option>
                      <option value="midwest">MidWest</option>
                    </select>
                    {adminError.score.region && (
                      <Err>{adminError.score.region}</Err>
                    )}
                  </div>
                  <div className="col-3">
                    <select name="seed" ref={register()} defaultValue="">
                      <option value="" disabled>
                        Seed
                      </option>
                      {SEEDS.map(seed => (
                        <option key={`seed-${seed}`} value={seed}>
                          {seed}
                        </option>
                      ))}
                    </select>
                    {adminError.score.seed && (
                      <Err>{adminError.score.seed}</Err>
                    )}
                  </div>
                  <div className="col-3">
                    <select name="round" ref={register()} defaultValue="">
                      <option value="" disabled>
                        Round
                      </option>
                      {ROUNDS.map(round => (
                        <option key={`round-${round.name}`} value={round.value}>
                          {round.name}
                        </option>
                      ))}
                    </select>
                    {adminError.score.round && (
                      <Err>{adminError.score.round}</Err>
                    )}
                  </div>
                  <div className="col-3">
                    <input
                      type="number"
                      name="score"
                      placeholder="Score"
                      ref={register()}
                    />
                    {adminError.score.score && (
                      <Err>{adminError.score.score}</Err>
                    )}
                  </div>
                  <div className="col-12 text-center">
                    <Button onClick={handleSubmit(scoreUpdate)}>
                      Update Team Score
                    </Button>
                    {adminError.score.submit && (
                      <>
                        <br />
                        <Err>{adminError.score.submit}</Err>
                      </>
                    )}
                    {adminScoreSuccess && (
                      <>
                        <br />
                        <Success>{adminScoreSuccess}</Success>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </>
      )
    }
  }
  const renderAdminControls = () => {
    if (loading) {
      return (
        <div className="row my-3">
          <div className="col-12 col-md-10 d-flex justify-content-center mr-auto ml-auto">
            <Loading color={`#ff0000`} size={`50`} />
          </div>
        </div>
      )
    } else if (user && user.IsAdmin) {
      return (
        <div className="row my-3">
          <div className="col-12 col-md-8 mr-auto ml-auto d-flex flex-column align-items-center">
            <InfoContainer>
              <h3>Admin Controls</h3>
            </InfoContainer>
          </div>
          <div className="col-12 my-3 text-center">
            <Button
              onClick={() => {
                setSeed(!seed)
                dispathAdminError({ type: "reset" })
              }}
            >
              Change to {seed ? "Scores Control" : "Inital Seeds Control"}
            </Button>
          </div>
          {renderForm()}
          <div className="col-12 text-center">
            <hr />
          </div>
          <div className="col-12 my-3 text-center">
            <Button onClick={() => handleBracketUpdate()}>
              Submit Bracket Changes
            </Button>
            {notify && (
              <>
                <br />
                <Info>{notify}</Info>
              </>
            )}
            {updateSuccess && <Success>{updateSuccess}</Success>}
            {updateError && <Error>{updateError}</Error>}
          </div>
          <div className="col-12 text-center">
            <hr />
          </div>
        </div>
      )
    }
  }
  const renderUserControls = () => {
    if (loading) {
      return (
        <div className="row my-3">
          <div className="col-12 col-md-10 d-flex justify-content-center mr-auto ml-auto">
            <Loading color={`#ff0000`} size={`50`} />
          </div>
        </div>
      )
    }
    return (
      <div className="row my-3">
        <div className="col-8 col-md-6 mr-auto ml-auto d-flex flex-column align-items-center">
          <InfoContainer>
            {user && user.IsAdmin && (
              <Button onClick={() => GetUsersCsv()}>Get All User Data</Button>
            )}
            {csvSuccess && <Success>{csvSuccess}</Success>}
            {csvError && <Error>{csvError}</Error>}
            <Button onClick={() => Logout()}>Logout</Button>
            {user && !user.IsAdmin && (
              <Button onClick={() => handleBracketPrint()}>Print</Button>
            )}
          </InfoContainer>
        </div>
      </div>
    )
  }
  const renderBracketInfo = () => {
    if (loading) {
      return (
        <div className="row my-3">
          <div className="col-12 col-md-10 d-flex justify-content-center mr-auto ml-auto">
            <Loading color={`#ff0000`} size={`50`} />
          </div>
        </div>
      )
    } else if (bracket && bracket.isReadOnly && !user.IsAdmin) {
      // has bracket, has submitted it and is not admin
      return (
        <div className="row my-3">
          <div className="col-12 col-md-8 d-flex flex-column align-items-center mr-auto ml-auto">
            <InfoContainer>
              {bracket.score >= 0 ? (
                <h3>
                  Score <span>{bracket.score}</span>
                </h3>
              ) : null}
              {bracket.rank > 0 ? (
                <h3>
                  Rank: <span>{appendSuffix(bracket.rank)}</span>
                </h3>
              ) : null}
            </InfoContainer>
          </div>
          {bracketSuccess && (
            <div className="col-12 col-md-8 d-flex flex-column align-items-center mr-auto ml-auto">
              <Success>{bracketSuccess}</Success>
            </div>
          )}
        </div>
      )
    }
  }
  const renderBracketFrozen = () => {
    if (bracket && !bracket.isReadOnly && !user.IsAdmin) {
      return (
        <div className="row my-3">
          <div className="col-8 col-md-6 mr-auto ml-auto d-flex flex-column align-items-center">
            <Info>Please fill out your bracket now</Info>
          </div>
        </div>
      )
    }
  }
  const renderBracket = () => {
    if (bracket) {
      return (
        <div className="row my-3">
          <div className="col-12 d-flex justify-content-center">
            <Bracket
              id={"brackettoprint"}
              cnt={cnt}
              frozen={bracket.isReadOnly}
            />
          </div>
        </div>
      )
    } else if (loading) {
      return (
        <div className="row my-3">
          <div className="col-12 col-md-10 d-flex justify-content-center mr-auto ml-auto">
            <Loading color={`#ff0000`} size={`100`} />
          </div>
        </div>
      )
    } else {
      return null
    }
  }
  const horizontalAd = data.horizontalAd.edges[0].node.childImageSharp.fluid
  return (
    <>
      {!isSSR && (
        <Suspense
          fallback={
            <div className="row my-3">
              <div className="col-12 col-md-10 d-flex justify-content-center mr-auto ml-auto">
                <Loading color={`#ff0000`} size={`100`} />
              </div>
            </div>
          }
        >
          <ClientPrintProvider>
            <Layout>
              <ClientNoPrint>
                <SEO title="Dixie Bracket | Dashboard" />
                <div className="container-fluid">
                  {renderDashboardTitle()}
                  {renderUserInfo()}
                  {renderAdminControls()}
                  {renderUserControls()}
                  {renderBracketInfo()}
                  <BgImage
                    fluid={horizontalAd}
                    alt="Brandon Hunt - State Farm"
                    imgStyle={{ objectFit: "contain" }}
                  />
                  {renderBracketFrozen()}
                  <ClientPrint single name={`print-bracket`}>
                    {renderBracket()}
                  </ClientPrint>
                </div>
              </ClientNoPrint>
            </Layout>
          </ClientPrintProvider>
        </Suspense>
      )}
    </>
  )
}

export const query = graphql`
  query dashboardQuery {
    horizontalAd: allFile(filter: { name: { eq: "horizontal_ad_728x90" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 728) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

const MapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    loading: state.auth.loading,
    bracket: state.auth.bracket,
    user: state.auth.user,
    bracketSuccess: state.auth.bracketSuccess,
    csvSuccess: state.auth.csvSuccess,
    csvError: state.auth.csvError,
    updateSuccess: state.auth.updateSuccess,
    updateError: state.auth.updateError,
  }
}

export default connect(MapStateToProps, actions)(DashboardPage)
