import React, { useEffect, useReducer } from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as actions from "../../redux/actions"
import { connect } from "react-redux"

import {
  BracketContainer,
  InnerContainer,
  TitleContainer,
  TitleHolder,
  Title,
  BracketLogoHolder,
  Image,
  Bracket,
  Regions,
  Region,
  Round,
  PlayPod,
  PlayPodCenter,
  PodStatus,
  PodState,
  Teams,
  Team,
  Seed,
  Name,
  Score,
  Check,
  RegionName,
  BracketLines,
  BracketLinesFlip,
  CenterFinal,
  CenterFinalRound,
  ChampRoundTitle,
  ChampTitle,
  CenterFinalGames,
  CenterFinalButtonContainer,
  SVG,
  G,
} from "./StyledParts"
import RadioSelect from "../RadioSelect"
import Button from "../Button"

import Reducer from "./Reducer"

const MasterBracket = ({
  authenticated,
  Logout,
  CreateBracket,
  id,
  cnt,
  bracket,
  frozen,
}) => {
  const data = useStaticQuery(graphql`
    query BracketQuery {
      CenterLogo: allFile(filter: { name: { eq: "Logo_216x216" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 216) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)
  const CenterLogo = data.CenterLogo.edges[0].node.childImageSharp.fluid
  const CenterLogoalt = `Brandon Hunt - State Farm`

  useEffect(() => {
    if (!authenticated) {
      Logout()
    }
  }, [])

  const handleBracketSubmit = () => {
    CreateBracket(state)
  }

  const renderDeduxTeam = (teamA, teamB, elimRnd) => {
    if (!teamA[elimRnd] && teamB[elimRnd]) {
      return teamA.team
    } else if (teamA[elimRnd] && !teamB[elimRnd]) {
      return teamB.team
    }
    return null
  }
  const renderDeduxSeed = (teamA, teamB, region, elimRnd) => {
    if (!region[teamA][elimRnd] && region[teamB][elimRnd]) {
      return teamA
    } else if (region[teamA][elimRnd] && !region[teamB][elimRnd]) {
      return teamB
    } else {
      return null
    }
  }
  const renderDeduxScore = (teamA, teamB, elimRnd) => {
    const scoreRnd = 2
    if (!teamA[elimRnd] && teamB[elimRnd]) {
      return teamA.score[scoreRnd] > 0 ? teamA.score[scoreRnd] : null
    } else if (teamA[elimRnd] && !teamB[elimRnd]) {
      return teamB.score[scoreRnd] > 0 ? teamB.score[scoreRnd] : null
    } else {
      return null
    }
  }
  const renderDeduxCheck = (rnd, teamA, teamB, check) => {
    if (teamA[rnd] !== teamB[rnd]) {
      if (check[rnd] === teamA[rnd]) {
        return teamB[rnd]
      }
      return teamA[rnd]
    }
    return false
  }
  // Rnd2 Dedux
  const renderDeduxCheckRnd2 = (region, teamA, teamB, rnd, check) => {
    if (region[teamA][rnd] !== region[teamB][rnd]) {
      if (region[check][rnd] === region[teamA][rnd]) {
        return region[teamB][rnd]
      }
      return region[teamA][rnd]
    }
    return false
  }
  const renderDeduxTeamRnd2 = (region, a, b, c, d, rnd) => {
    if (!region[a][rnd] && region[b][rnd] && region[c][rnd] && region[d][rnd]) {
      return region[a].team
    }
    if (!region[b][rnd] && region[a][rnd] && region[c][rnd] && region[d][rnd]) {
      return region[b].team
    }
    if (!region[c][rnd] && region[b][rnd] && region[a][rnd] && region[d][rnd]) {
      return region[c].team
    }
    if (!region[d][rnd] && region[b][rnd] && region[c][rnd] && region[a][rnd]) {
      return region[d].team
    }
    return null
  }
  const renderRnd2Select = (radioName, region, regionName, a, b, c, d) => {
    if (
      renderDeduxSeed(a, b, region, "isElimOne") &&
      renderDeduxSeed(c, d, region, "isElimOne")
    ) {
      return (
        <RadioSelect
          disabled={frozen}
          name={radioName}
          value={renderDeduxCheckRnd2(
            region,
            renderDeduxSeed(a, b, region, "isElimOne"),
            renderDeduxSeed(c, d, region, "isElimOne"),
            "isElimTwo",
            renderDeduxSeed(a, b, region, "isElimOne")
          )}
          handleRadioChange={() =>
            dispatch({
              type: "isElimTwo",
              payload: {
                region: regionName,
                winner: renderDeduxSeed(a, b, region, "isElimOne"),
                loser: renderDeduxSeed(c, d, region, "isElimOne"),
              },
            })
          }
        />
      )
    }
    return null
  }
  // Rnd3 Dedux
  const renderDeduxTeamRnd3 = (region, a, b, c, d) => {
    const rnd = "isElimTwo"
    if (!region[a][rnd] && region[b][rnd] && region[c][rnd] && region[d][rnd]) {
      return region[a].team
    }
    if (!region[b][rnd] && region[a][rnd] && region[c][rnd] && region[d][rnd]) {
      return region[b].team
    }
    if (!region[c][rnd] && region[b][rnd] && region[a][rnd] && region[d][rnd]) {
      return region[c].team
    }
    if (!region[d][rnd] && region[b][rnd] && region[c][rnd] && region[a][rnd]) {
      return region[d].team
    }
    return null
  }
  const renderDeduxSeedRnd3 = (region, a, b, c, d) => {
    const rnd = "isElimTwo"
    if (!region[a][rnd] && region[b][rnd] && region[c][rnd] && region[d][rnd]) {
      return a
    }
    if (!region[b][rnd] && region[a][rnd] && region[c][rnd] && region[d][rnd]) {
      return b
    }
    if (!region[c][rnd] && region[b][rnd] && region[a][rnd] && region[d][rnd]) {
      return c
    }
    if (!region[d][rnd] && region[b][rnd] && region[c][rnd] && region[a][rnd]) {
      return d
    }
    return null
  }
  const renderDeduxScoreRnd3 = (region, a, b, c, d) => {
    const rnd = "isElimTwo"
    const scoreRnd = 3
    if (!region[a][rnd] && region[b][rnd] && region[c][rnd] && region[d][rnd]) {
      return region[a].score[scoreRnd] > 0 ? region[a].score[scoreRnd] : null
    }
    if (!region[b][rnd] && region[a][rnd] && region[c][rnd] && region[d][rnd]) {
      return region[b].score[scoreRnd] > 0 ? region[b].score[scoreRnd] : null
    }
    if (!region[c][rnd] && region[b][rnd] && region[a][rnd] && region[d][rnd]) {
      return region[c].score[scoreRnd] > 0 ? region[c].score[scoreRnd] : null
    }
    if (!region[d][rnd] && region[b][rnd] && region[c][rnd] && region[a][rnd]) {
      return region[d].score[scoreRnd] > 0 ? region[d].score[scoreRnd] : null
    }
    return null
  }
  const renderDeduxWinnerRnd3 = (region, a, b, c, d, e, f, g, h) => {
    const rnd = "isElimThree"
    if (
      !region[a][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[a].team
    }
    if (
      !region[b][rnd] &&
      region[a][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[b].team
    }
    if (
      !region[c][rnd] &&
      region[b][rnd] &&
      region[a][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[c].team
    }
    if (
      !region[d][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[a][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[d].team
    }
    if (
      !region[e][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[a][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[e].team
    }
    if (
      !region[f][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[a][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[f].team
    }
    if (
      !region[g][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[a][rnd] &&
      region[h][rnd]
    ) {
      return region[g].team
    }
    if (
      !region[h][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[a][rnd]
    ) {
      return region[h].team
    }
    return null
  }
  const renderRnd3Select = (
    radioName,
    region,
    regionName,
    a,
    b,
    c,
    d,
    e,
    f,
    g,
    h
  ) => {
    if (
      renderDeduxSeedRnd3(region, a, b, c, d) &&
      renderDeduxSeedRnd3(region, e, f, g, h)
    ) {
      return (
        <RadioSelect
          disabled={frozen}
          name={radioName}
          value={renderDeduxCheckRnd2(
            region,
            renderDeduxSeedRnd3(region, a, b, c, d),
            renderDeduxSeedRnd3(region, e, f, g, h),
            "isElimThree",
            renderDeduxSeedRnd3(region, a, b, c, d)
          )}
          handleRadioChange={() =>
            dispatch({
              type: "isElimThree",
              payload: {
                region: regionName,
                winner: renderDeduxSeedRnd3(region, a, b, c, d),
                loser: renderDeduxSeedRnd3(region, e, f, g, h),
              },
            })
          }
        />
      )
    }
    return null
  }
  // Rnd4 Dedux
  const renderDeduxTeamRnd4 = (region, a, b, c, d, e, f, g, h) => {
    const rnd = "isElimThree"
    if (
      !region[a][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[a].team
    }
    if (
      !region[b][rnd] &&
      region[a][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[b].team
    }
    if (
      !region[c][rnd] &&
      region[b][rnd] &&
      region[a][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[c].team
    }
    if (
      !region[d][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[a][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[d].team
    }
    if (
      !region[e][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[a][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[e].team
    }
    if (
      !region[f][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[a][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[f].team
    }
    if (
      !region[g][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[a][rnd] &&
      region[h][rnd]
    ) {
      return region[g].team
    }
    if (
      !region[h][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[a][rnd]
    ) {
      return region[h].team
    }
  }
  const renderDeduxSeedRnd4 = (region, a, b, c, d, e, f, g, h) => {
    const rnd = "isElimThree"
    if (
      !region[a][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return a
    }
    if (
      !region[b][rnd] &&
      region[a][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return b
    }
    if (
      !region[c][rnd] &&
      region[b][rnd] &&
      region[a][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return c
    }
    if (
      !region[d][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[a][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return d
    }
    if (
      !region[e][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[a][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return e
    }
    if (
      !region[f][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[a][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return f
    }
    if (
      !region[g][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[a][rnd] &&
      region[h][rnd]
    ) {
      return g
    }
    if (
      !region[h][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[a][rnd]
    ) {
      return h
    }
  }
  const renderDeduxScoreRnd4 = (region, a, b, c, d, e, f, g, h) => {
    const rnd = "isElimThree"
    const scoreRnd = 4
    if (
      !region[a][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[a].score[scoreRnd] > 0 ? region[a].score[scoreRnd] : null
    }
    if (
      !region[b][rnd] &&
      region[a][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[b].score[scoreRnd] > 0 ? region[b].score[scoreRnd] : null
    }
    if (
      !region[c][rnd] &&
      region[b][rnd] &&
      region[a][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[c].score[scoreRnd] > 0 ? region[c].score[scoreRnd] : null
    }
    if (
      !region[d][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[a][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[d].score[scoreRnd] > 0 ? region[d].score[scoreRnd] : null
    }
    if (
      !region[e][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[a][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[e].score[scoreRnd] > 0 ? region[e].score[scoreRnd] : null
    }
    if (
      !region[f][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[a][rnd] &&
      region[g][rnd] &&
      region[h][rnd]
    ) {
      return region[f].score[scoreRnd] > 0 ? region[f].score[scoreRnd] : null
    }
    if (
      !region[g][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[a][rnd] &&
      region[h][rnd]
    ) {
      return region[g].score[scoreRnd] > 0 ? region[g].score[scoreRnd] : null
    }
    if (
      !region[h][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[a][rnd]
    ) {
      return region[h].score[scoreRnd] > 0 ? region[h].score[scoreRnd] : null
    }
    return null
  }
  const renderDeduxWinnerRnd4 = (
    region,
    a,
    b,
    c,
    d,
    e,
    f,
    g,
    h,
    i,
    j,
    k,
    l,
    m,
    n,
    o,
    p
  ) => {
    const rnd = "isElimFour"
    if (
      !region[a][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd] &&
      region[i][rnd] &&
      region[j][rnd] &&
      region[k][rnd] &&
      region[l][rnd] &&
      region[m][rnd] &&
      region[n][rnd] &&
      region[o][rnd] &&
      region[p][rnd]
    ) {
      return region[a].team
    }
    if (
      !region[b][rnd] &&
      region[a][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd] &&
      region[i][rnd] &&
      region[j][rnd] &&
      region[k][rnd] &&
      region[l][rnd] &&
      region[m][rnd] &&
      region[n][rnd] &&
      region[o][rnd] &&
      region[p][rnd]
    ) {
      return region[b].team
    }
    if (
      !region[c][rnd] &&
      region[b][rnd] &&
      region[a][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd] &&
      region[i][rnd] &&
      region[j][rnd] &&
      region[k][rnd] &&
      region[l][rnd] &&
      region[m][rnd] &&
      region[n][rnd] &&
      region[o][rnd] &&
      region[p][rnd]
    ) {
      return region[c].team
    }
    if (
      !region[d][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[a][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd] &&
      region[i][rnd] &&
      region[j][rnd] &&
      region[k][rnd] &&
      region[l][rnd] &&
      region[m][rnd] &&
      region[n][rnd] &&
      region[o][rnd] &&
      region[p][rnd]
    ) {
      return region[d].team
    }
    if (
      !region[e][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[a][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd] &&
      region[i][rnd] &&
      region[j][rnd] &&
      region[k][rnd] &&
      region[l][rnd] &&
      region[m][rnd] &&
      region[n][rnd] &&
      region[o][rnd] &&
      region[p][rnd]
    ) {
      return region[e].team
    }
    if (
      !region[f][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[a][rnd] &&
      region[g][rnd] &&
      region[h][rnd] &&
      region[i][rnd] &&
      region[j][rnd] &&
      region[k][rnd] &&
      region[l][rnd] &&
      region[m][rnd] &&
      region[n][rnd] &&
      region[o][rnd] &&
      region[p][rnd]
    ) {
      return region[f].team
    }
    if (
      !region[g][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[a][rnd] &&
      region[h][rnd] &&
      region[i][rnd] &&
      region[j][rnd] &&
      region[k][rnd] &&
      region[l][rnd] &&
      region[m][rnd] &&
      region[n][rnd] &&
      region[o][rnd] &&
      region[p][rnd]
    ) {
      return region[g].team
    }
    if (
      !region[h][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[a][rnd] &&
      region[i][rnd] &&
      region[j][rnd] &&
      region[k][rnd] &&
      region[l][rnd] &&
      region[m][rnd] &&
      region[n][rnd] &&
      region[o][rnd] &&
      region[p][rnd]
    ) {
      return region[h].team
    }
    if (
      !region[i][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd] &&
      region[a][rnd] &&
      region[j][rnd] &&
      region[k][rnd] &&
      region[l][rnd] &&
      region[m][rnd] &&
      region[n][rnd] &&
      region[o][rnd] &&
      region[p][rnd]
    ) {
      return region[i].team
    }
    if (
      !region[j][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd] &&
      region[i][rnd] &&
      region[a][rnd] &&
      region[k][rnd] &&
      region[l][rnd] &&
      region[m][rnd] &&
      region[n][rnd] &&
      region[o][rnd] &&
      region[p][rnd]
    ) {
      return region[j].team
    }
    if (
      !region[k][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd] &&
      region[i][rnd] &&
      region[j][rnd] &&
      region[a][rnd] &&
      region[l][rnd] &&
      region[m][rnd] &&
      region[n][rnd] &&
      region[o][rnd] &&
      region[p][rnd]
    ) {
      return region[k].team
    }
    if (
      !region[l][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd] &&
      region[i][rnd] &&
      region[j][rnd] &&
      region[k][rnd] &&
      region[a][rnd] &&
      region[m][rnd] &&
      region[n][rnd] &&
      region[o][rnd] &&
      region[p][rnd]
    ) {
      return region[l].team
    }
    if (
      !region[m][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd] &&
      region[i][rnd] &&
      region[j][rnd] &&
      region[k][rnd] &&
      region[l][rnd] &&
      region[a][rnd] &&
      region[n][rnd] &&
      region[o][rnd] &&
      region[p][rnd]
    ) {
      return region[m].team
    }
    if (
      !region[n][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd] &&
      region[i][rnd] &&
      region[j][rnd] &&
      region[k][rnd] &&
      region[l][rnd] &&
      region[m][rnd] &&
      region[a][rnd] &&
      region[o][rnd] &&
      region[p][rnd]
    ) {
      return region[n].team
    }
    if (
      !region[o][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd] &&
      region[i][rnd] &&
      region[j][rnd] &&
      region[k][rnd] &&
      region[l][rnd] &&
      region[m][rnd] &&
      region[n][rnd] &&
      region[a][rnd] &&
      region[p][rnd]
    ) {
      return region[o].team
    }
    if (
      !region[p][rnd] &&
      region[b][rnd] &&
      region[c][rnd] &&
      region[d][rnd] &&
      region[e][rnd] &&
      region[f][rnd] &&
      region[g][rnd] &&
      region[h][rnd] &&
      region[i][rnd] &&
      region[j][rnd] &&
      region[k][rnd] &&
      region[l][rnd] &&
      region[m][rnd] &&
      region[n][rnd] &&
      region[o][rnd] &&
      region[a][rnd]
    ) {
      return region[p].team
    }
    return null
  }
  const renderRnd4Select = (
    radioName,
    region,
    regionName,
    a,
    b,
    c,
    d,
    e,
    f,
    g,
    h,
    i,
    j,
    k,
    l,
    m,
    n,
    o,
    p
  ) => {
    if (
      renderDeduxSeedRnd4(region, a, b, c, d, e, f, g, h) &&
      renderDeduxSeedRnd4(region, i, j, k, l, m, n, o, p)
    ) {
      return (
        <RadioSelect
          disabled={frozen}
          name={radioName}
          value={renderDeduxCheckRnd2(
            region,
            renderDeduxSeedRnd4(region, a, b, c, d, e, f, g, h),
            renderDeduxSeedRnd4(region, i, j, k, l, m, n, o, p),
            "isElimFour",
            renderDeduxSeedRnd4(region, a, b, c, d, e, f, g, h)
          )}
          handleRadioChange={() =>
            dispatch({
              type: "isElimFour",
              payload: {
                region: regionName,
                winner: renderDeduxSeedRnd4(region, a, b, c, d, e, f, g, h),
                loser: renderDeduxSeedRnd4(region, i, j, k, l, m, n, o, p),
              },
            })
          }
        />
      )
    }
    return null
  }
  // Final Four Dedux
  const renderDeduxTeamFinalFour = region => {
    const rnd = "isElimFour"
    const check = Object.keys(region).filter(key => {
      if (region[key][rnd] === false) {
        return key
      }
    })
    if (check.length === 1) {
      return region[check[0]].team
    }
    return null
  }
  const renderDeduxSeedFinalFour = region => {
    const rnd = "isElimFour"
    const check = Object.keys(region).filter(key => {
      if (region[key][rnd] === false) {
        return key
      }
    })
    if (check.length === 1) {
      return check[0]
    }
    return null
  }
  const renderDeduxScoreFinalFour = region => {
    const rnd = "isElimFour"
    const scoreRnd = 5
    const check = Object.keys(region).filter(key => {
      if (region[key][rnd] === false) {
        return key
      }
    })
    if (check.length === 1) {
      return region[check[0]].score[scoreRnd] > 0
        ? region[check[0]].score[scoreRnd]
        : null
    }
    return null
  }
  const renderDeduxWinnerFinalFour = (regionA, regionB) => {
    const rnd = "isElimFive"
    const checkA = Object.keys(regionA).filter(key => {
      if (regionA[key][rnd] === false) {
        return key
      }
    })
    const checkB = Object.keys(regionB).filter(key => {
      if (regionB[key][rnd] === false) {
        return key
      }
    })
    if (checkA.length === 1 && checkB.length === 0) {
      return regionA[checkA[0]].team
    }
    if (checkB.length === 1 && checkA.length === 0) {
      return regionB[checkB[0]].team
    }
    return null
  }
  const renderDeduxCheckFinalFour = (regionA, a, regionB, b) => {
    const rnd = "isElimFive"
    const teamA = regionA[a][rnd]
    const teamB = regionB[b][rnd]
    const checkA = regionA[a][rnd]

    if (teamA !== teamB) {
      if (checkA === teamA) {
        return teamB
      }
      return teamA
    }
    return false
  }
  const renderFinalFourSelect = (
    radioName,
    regionA,
    regionB,
    regionNameWin,
    regionNameLose
  ) => {
    const a = renderDeduxSeedFinalFour(regionA)
    const b = renderDeduxSeedFinalFour(regionB)
    if (a && b) {
      return (
        <RadioSelect
          disabled={frozen}
          name={radioName}
          value={renderDeduxCheckFinalFour(regionA, a, regionB, b)}
          handleRadioChange={() =>
            dispatch({
              type: "isElimFive",
              payload: {
                regionWin: regionNameWin,
                regionLose: regionNameLose,
                winner: a,
                loser: b,
              },
            })
          }
        />
      )
    }
    return null
  }
  // Championship Dedux
  const renderDeduxTeamChampionship = (regionA, regionB) => {
    const rnd = "isElimFive"
    const checkA = Object.keys(regionA).filter(key => {
      if (regionA[key][rnd] === false) {
        return key
      }
    })
    const checkB = Object.keys(regionB).filter(key => {
      if (regionB[key][rnd] === false) {
        return key
      }
    })
    if (checkA.length === 1 && checkB.length === 0) {
      return regionA[checkA[0]].team
    }
    if (checkB.length === 1 && checkA.length === 0) {
      return regionB[checkB[0]].team
    }
    return null
  }
  const renderDeduxSeedChampionship = (regionA, regionB) => {
    const rnd = "isElimFive"
    const checkA = Object.keys(regionA).filter(key => {
      if (regionA[key][rnd] === false) {
        return key
      }
    })
    const checkB = Object.keys(regionB).filter(key => {
      if (regionB[key][rnd] === false) {
        return key
      }
    })
    if (checkA.length === 1 && checkB.length === 0) {
      return checkA[0]
    }
    if (checkB.length === 1 && checkA.length === 0) {
      return checkB[0]
    }
    return null
  }
  const renderDeduxScoreChampionship = (regionA, regionB) => {
    const rnd = "isElimFive"
    const scoreRnd = 6
    const checkA = Object.keys(regionA).filter(key => {
      if (regionA[key][rnd] === false) {
        return key
      }
    })
    const checkB = Object.keys(regionB).filter(key => {
      if (regionB[key][rnd] === false) {
        return key
      }
    })
    if (checkA.length === 1 && checkB.length === 0) {
      return regionA[checkA[0]].score[scoreRnd] > 0
        ? regionA[checkA[0]].score[scoreRnd]
        : null
    }
    if (checkB.length === 1 && checkA.length === 0) {
      return regionB[checkB[0]].score[scoreRnd] > 0
        ? regionB[checkB[0]].score[scoreRnd]
        : null
    }
    return null
  }
  const renderDeduxWinnerChampionship = (
    regionA,
    regionB,
    regionC,
    regionD
  ) => {
    const rnd = "isElimSix"
    const checkA = Object.keys(regionA).filter(key => {
      if (regionA[key][rnd] === false) {
        return key
      }
    })
    const checkB = Object.keys(regionB).filter(key => {
      if (regionB[key][rnd] === false) {
        return key
      }
    })
    const checkC = Object.keys(regionC).filter(key => {
      if (regionC[key][rnd] === false) {
        return key
      }
    })
    const checkD = Object.keys(regionD).filter(key => {
      if (regionD[key][rnd] === false) {
        return key
      }
    })
    // find one champ out of all 64 possible
    if (
      checkA.length === 1 &&
      checkB.length === 0 &&
      checkC.length === 0 &&
      checkD.length === 0
    ) {
      return regionA[checkA[0]].team
    }
    if (
      checkB.length === 1 &&
      checkA.length === 0 &&
      checkC.length === 0 &&
      checkD.length === 0
    ) {
      return regionB[checkB[0]].team
    }
    if (
      checkC.length === 1 &&
      checkB.length === 0 &&
      checkA.length === 0 &&
      checkD.length === 0
    ) {
      return regionC[checkC[0]].team
    }
    if (
      checkD.length === 1 &&
      checkB.length === 0 &&
      checkC.length === 0 &&
      checkA.length === 0
    ) {
      return regionD[checkD[0]].team
    }
    return null
  }
  const renderDeduxSeedRegionChamp = (regionA, nameA, regionB, nameB) => {
    const rnd = "isElimFive"
    const checkA = Object.keys(regionA).filter(key => {
      if (regionA[key][rnd] === false) {
        return key
      }
    })
    const checkB = Object.keys(regionB).filter(key => {
      if (regionB[key][rnd] === false) {
        return key
      }
    })
    if (checkA.length === 1 && checkB.length === 0) {
      return [checkA[0], regionA, nameA]
    }
    if (checkB.length === 1 && checkA.length === 0) {
      return [checkB[0], regionB, nameB]
    }
    return [null, null]
  }
  const renderDeduxCheckChampionship = (regionAB, ab, regionCD, cd) => {
    const rnd = "isElimSix"
    const teamAB = regionAB[ab][rnd]
    const checkAB = regionAB[ab][rnd]
    const teamCD = regionCD[cd][rnd]

    if (teamAB !== teamCD) {
      if (checkAB === teamAB) {
        return teamCD
      }
      return teamAB
    }
    return false
  }
  const renderDeduxChampionshipSelect = (
    radioName,
    regionA,
    nameA,
    regionB,
    nameB,
    regionC,
    nameC,
    regionD,
    nameD
  ) => {
    const [ab, regionAB, nameAB] = renderDeduxSeedRegionChamp(
      regionA,
      nameA,
      regionB,
      nameB
    )
    const [cd, regionCD, nameCD] = renderDeduxSeedRegionChamp(
      regionC,
      nameC,
      regionD,
      nameD
    )
    if (ab && cd) {
      return (
        <RadioSelect
          disabled={frozen}
          name={radioName}
          value={renderDeduxCheckChampionship(regionAB, ab, regionCD, cd)}
          handleRadioChange={() =>
            dispatch({
              type: "isElimSix",
              payload: {
                regionWin: nameAB,
                regionLose: nameCD,
                winner: ab,
                loser: cd,
              },
            })
          }
        />
      )
    }
    return null
  }
  const renderSubmit = () => {
    if (renderDeduxWinnerChampionship(east, west, south, midwest) && !frozen) {
        return <Button onClick={() => handleBracketSubmit()}>Submit Bracket</Button>
    }
  }
  const [state, dispatch] = useReducer(Reducer, bracket)
  // spread state
  const { south, west, east, midwest } = state

  useEffect(() => {
    if (bracket !== state) {
      console.log("Bracket Updated")
    }
  }, [cnt])

  return (
    <BracketContainer id={id}>
      <InnerContainer>
        <Bracket>
          <TitleContainer>
            <TitleHolder width={17.4}>
              <Title align={"left"}>First Round</Title>
            </TitleHolder>
            <TitleHolder width={9.74}>
              <Title align={"left"}>Second Round</Title>
            </TitleHolder>
            <TitleHolder width={9.74}>
              <Title align={"right"}>Round of Sixteen</Title>
            </TitleHolder>
            <TitleHolder width={9.74}>
              <Title align={"right"}>Round of Eight</Title>
            </TitleHolder>
            <TitleHolder width={9.74}>
              <Title align={"left"}>Round of Eight</Title>
            </TitleHolder>
            <TitleHolder width={9.74}>
              <Title align={"left"}>Round of Sixteen</Title>
            </TitleHolder>
            <TitleHolder width={9.74}>
              <Title align={"right"}>Second Round</Title>
            </TitleHolder>
            <TitleHolder width={17.4}>
              <Title align={"right"}>First Round</Title>
            </TitleHolder>
          </TitleContainer>
          <Regions>
            <Region>
              <RegionName>East</RegionName>
              <Round>
                <PlayPod top={20} left={0}>
                  {/* Rnd1 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(east["1"], east["16"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>1</Seed>
                      <Name>{east["1"].team}</Name>
                      <Score>
                        {east["1"].score[1] > 0 ? east["1"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-1-16"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["1"],
                            east["16"],
                            east["1"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 1,
                                loser: 16,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>16</Seed>
                      <Name>{east["16"].team}</Name>
                      <Score>
                        {east["16"].score[1] > 0 ? east["16"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-1-16"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["16"],
                            east["1"],
                            east["16"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 16,
                                loser: 1,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines left={205} top={35}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-372.000000, -741.000000)">
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
                <PlayPod top={135} left={0}>
                  {/* Rnd1 Two */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(east["8"], east["9"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>8</Seed>
                      <Name>{east["8"].team}</Name>
                      <Score>
                        {east["8"].score[1] > 0 ? east["8"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-8-9"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["8"],
                            east["9"],
                            east["8"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 8,
                                loser: 9,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>9</Seed>
                      <Name>{east["9"].team}</Name>
                      <Score>
                        {east["9"].score[1] > 0 ? east["9"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-8-9"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["9"],
                            east["8"],
                            east["9"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 9,
                                loser: 8,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip left={205} top={110}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-372.000000, -741.000000)">
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={250} left={0}>
                  {/* Rnd1 Three */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(east["5"], east["12"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>5</Seed>
                      <Name>{east["5"].team}</Name>
                      <Score>
                        {east["5"].score[1] > 0 ? east["5"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-5-12"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["5"],
                            east["12"],
                            east["5"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 5,
                                loser: 12,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>12</Seed>
                      <Name>{east["12"].team}</Name>
                      <Score>
                        {east["12"].score[1] > 0 ? east["12"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-5-12"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["12"],
                            east["5"],
                            east["12"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 12,
                                loser: 5,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines left={205} top={265}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-372.000000, -741.000000)">
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
                <PlayPod top={365} left={0}>
                  {/* Rnd1 Four */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(east["4"], east["13"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>4</Seed>
                      <Name>{east["4"].team}</Name>
                      <Score>
                        {east["4"].score[1] > 0 ? east["4"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-4-13"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["4"],
                            east["13"],
                            east["4"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 4,
                                loser: 13,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>13</Seed>
                      <Name>{east["13"].team}</Name>
                      <Score>
                        {east["13"].score[1] > 0 ? east["13"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-4-13"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["13"],
                            east["4"],
                            east["13"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 13,
                                loser: 4,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip left={205} top={340}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-372.000000, -741.000000)">
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={480} left={0}>
                  {/* Rnd 1 Five */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(east["6"], east["11"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>6</Seed>
                      <Name>{east["6"].team}</Name>
                      <Score>
                        {east["6"].score[1] > 0 ? east["6"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-6-11"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["6"],
                            east["11"],
                            east["6"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 6,
                                loser: 11,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>11</Seed>
                      <Name>{east["11"].team}</Name>
                      <Score>
                        {east["11"].score[1] > 0 ? east["11"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-6-11"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["11"],
                            east["6"],
                            east["11"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 11,
                                loser: 6,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines left={205} top={495}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-372.000000, -741.000000)">
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
                <PlayPod top={595} left={0}>
                  {/* Rnd1 Six */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(east["3"], east["14"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>3</Seed>
                      <Name>{east["3"].team}</Name>
                      <Score>
                        {east["3"].score[1] > 0 ? east["3"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-3-14"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["3"],
                            east["14"],
                            east["3"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 3,
                                loser: 14,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>14</Seed>
                      <Name>{east["14"].team}</Name>
                      <Score>
                        {east["14"].score[1] > 0 ? east["14"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-3-14"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["14"],
                            east["3"],
                            east["14"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 14,
                                loser: 3,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip left={205} top={570}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-372.000000, -741.000000)">
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={710} left={0}>
                  {/* Rnd 1 Seven */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(east["7"], east["10"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>7</Seed>
                      <Name>{east["7"].team}</Name>
                      <Score>
                        {east["7"].score[1] > 0 ? east["7"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-7-10"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["7"],
                            east["10"],
                            east["7"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 7,
                                loser: 10,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>10</Seed>
                      <Name>{east["10"].team}</Name>
                      <Score>
                        {east["10"].score[1] > 0 ? east["10"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-7-10"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["10"],
                            east["7"],
                            east["10"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 10,
                                loser: 7,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines left={205} top={725}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-372.000000, -741.000000)">
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
                <PlayPod top={825} left={0}>
                  {/* Rnd1 Eight */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(east["2"], east["15"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>2</Seed>
                      <Name>{east["2"].team}</Name>
                      <Score>
                        {east["2"].score[1] > 0 ? east["2"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-2-15"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["2"],
                            east["15"],
                            east["2"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 2,
                                loser: 15,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>15</Seed>
                      <Name>{east["15"].team}</Name>
                      <Score>
                        {east["15"].score[1] > 0 ? east["15"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"east-2-15"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            east["15"],
                            east["2"],
                            east["15"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "east",
                                winner: 15,
                                loser: 2,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip left={205} top={800}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-372.000000, -741.000000)">
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLinesFlip>
              </Round>
              <Round>
                <PlayPod top={75} left={225}>
                  {/* Rnd2 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        east,
                        "1",
                        "16",
                        "8",
                        "9",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("1", "16", east, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(east["1"], east["16"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(east["1"], east["16"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "east-rnd2-1",
                          east,
                          "east",
                          "1",
                          "16",
                          "8",
                          "9"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("8", "9", east, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(east["8"], east["9"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(east["8"], east["9"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "east-rnd2-1",
                          east,
                          "east",
                          "8",
                          "9",
                          "1",
                          "16"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines left={430} top={90}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-372.000000, -741.000000)">
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
                <PlayPod top={305} left={225}>
                  {/* Rnd2 Two */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        east,
                        "5",
                        "12",
                        "4",
                        "13",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("5", "12", east, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(east["5"], east["12"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(east["5"], east["12"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "east-rnd2-2",
                          east,
                          "east",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("4", "13", east, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(east["4"], east["13"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(east["4"], east["13"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "east-rnd2-2",
                          east,
                          "east",
                          "4",
                          "13",
                          "5",
                          "12"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip left={430} top={280}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-372.000000, -741.000000)">
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={535} left={225}>
                  {/* Rnd2 Three */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        east,
                        "6",
                        "11",
                        "3",
                        "14",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("6", "11", east, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(east["6"], east["11"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(east["6"], east["11"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "east-rnd2-3",
                          east,
                          "east",
                          "6",
                          "11",
                          "3",
                          "14"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("3", "14", east, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(east["3"], east["14"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(east["3"], east["14"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "east-rnd2-3",
                          east,
                          "east",
                          "3",
                          "14",
                          "6",
                          "11"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines left={430} top={550}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-372.000000, -741.000000)">
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
                <PlayPod top={765} left={225}>
                  {/* Rnd2 Four */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        east,
                        "7",
                        "10",
                        "2",
                        "15",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("7", "10", east, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(east["7"], east["10"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(east["7"], east["10"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "east-rnd2-4",
                          east,
                          "east",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("2", "15", east, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(east["2"], east["15"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(east["2"], east["15"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "east-rnd2-4",
                          east,
                          "east",
                          "2",
                          "15",
                          "7",
                          "10"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip left={430} top={740}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-372.000000, -741.000000)">
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLinesFlip>
              </Round>
              <Round>
                <PlayPod top={190} left={310}>
                  {/* Rnd3 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxWinnerRnd3(
                        east,
                        "1",
                        "16",
                        "8",
                        "9",
                        "5",
                        "12",
                        "4",
                        "13"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(east, "1", "16", "8", "9")}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(east, "1", "16", "8", "9")}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(east, "1", "16", "8", "9")}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "east-rnd3-1",
                          east,
                          "east",
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(
                          east,
                          "5",
                          "12",
                          "4",
                          "13",
                          "isElimTwo"
                        )}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(
                          east,
                          "5",
                          "12",
                          "4",
                          "13",
                          "isElimTwo"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(
                          east,
                          "5",
                          "12",
                          "4",
                          "13",
                          "isElimTwo"
                        )}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "east-rnd3-1",
                          east,
                          "east",
                          "5",
                          "12",
                          "4",
                          "13",
                          "1",
                          "16",
                          "8",
                          "9"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines right={34} top={240} height={200}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-372.000000, -741.000000)">
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
                <PlayPod top={650} left={310}>
                  {/* Rnd3 Two */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxWinnerRnd3(
                        east,
                        "6",
                        "11",
                        "3",
                        "14",
                        "7",
                        "10",
                        "2",
                        "15"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(east, "6", "11", "3", "14")}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(east, "6", "11", "3", "14")}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(east, "6", "11", "3", "14")}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "east-rnd3-1",
                          east,
                          "east",
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(
                          east,
                          "7",
                          "10",
                          "2",
                          "15",
                          "isElimTwo"
                        )}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(
                          east,
                          "7",
                          "10",
                          "2",
                          "15",
                          "isElimTwo"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(
                          east,
                          "7",
                          "10",
                          "2",
                          "15",
                          "isElimTwo"
                        )}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "east-rnd3-1",
                          east,
                          "east",
                          "7",
                          "10",
                          "2",
                          "15",
                          "6",
                          "11",
                          "3",
                          "14"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip right={34} bottom={210} height={200}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-372.000000, -741.000000)">
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLinesFlip>
              </Round>
              <Round>
                <PlayPod top={420} left={360}>
                  {/* Rnd4 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxWinnerRnd4(
                        east,
                        "1",
                        "16",
                        "8",
                        "9",
                        "5",
                        "12",
                        "4",
                        "13",
                        "6",
                        "11",
                        "3",
                        "14",
                        "7",
                        "10",
                        "2",
                        "15"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd4(
                          east,
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd4(
                          east,
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd4(
                          east,
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Score>
                      <Check>
                        {renderRnd4Select(
                          "east-rnd4-1",
                          east,
                          "east",
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13",
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd4(
                          east,
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd4(
                          east,
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd4(
                          east,
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Score>
                      <Check>
                        {renderRnd4Select(
                          "east-rnd4-1",
                          east,
                          "east",
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15",
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines right={4} top={475} height={400}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-372.000000, -741.000000)">
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
              </Round>
            </Region>
            <Region>
              <RegionName>South</RegionName>
              <Round>
                <PlayPod top={20} right={0}>
                  {/* Rnd1 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(south["1"], south["16"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>1</Seed>
                      <Name>{south["1"].team}</Name>
                      <Score>
                        {south["1"].score[1] > 0 ? south["1"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-1-16"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["1"],
                            south["16"],
                            south["1"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 1,
                                loser: 16,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>16</Seed>
                      <Name>{south["16"].team}</Name>
                      <Score>
                        {south["16"].score[1] > 0 ? south["16"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-1-16"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["16"],
                            south["1"],
                            south["16"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 16,
                                loser: 1,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines right={205} top={35}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-1034.000000, -737.000000)">
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
                <PlayPod top={135} right={0}>
                  {/* Rnd1 Two */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(south["8"], south["9"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>8</Seed>
                      <Name>{south["8"].team}</Name>
                      <Score>
                        {south["8"].score[1] > 0 ? south["8"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-8-9"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["8"],
                            south["9"],
                            south["8"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 8,
                                loser: 9,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>9</Seed>
                      <Name>{south["9"].team}</Name>
                      <Score>
                        {south["9"].score[1] > 0 ? south["9"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-8-9"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["9"],
                            south["8"],
                            south["9"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 9,
                                loser: 8,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip right={205} top={110}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-1034.000000, -737.000000)">
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={250} right={0}>
                  {/* Rnd1 Three */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(south["5"], south["12"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>5</Seed>
                      <Name>{south["5"].team}</Name>
                      <Score>
                        {south["5"].score[1] > 0 ? south["5"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-5-12"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["5"],
                            south["12"],
                            south["5"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 5,
                                loser: 12,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>12</Seed>
                      <Name>{south["12"].team}</Name>
                      <Score>
                        {south["12"].score[1] > 0 ? south["12"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-5-12"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["12"],
                            south["5"],
                            south["12"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 12,
                                loser: 5,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines right={205} top={265}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-1034.000000, -737.000000)">
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
                <PlayPod top={365} right={0}>
                  {/* Rnd1 Four */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(south["4"], south["13"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>4</Seed>
                      <Name>{south["4"].team}</Name>
                      <Score>
                        {south["4"].score[1] > 0 ? south["4"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-4-13"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["4"],
                            south["13"],
                            south["4"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 4,
                                loser: 13,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>13</Seed>
                      <Name>{south["13"].team}</Name>
                      <Score>
                        {south["13"].score[1] > 0 ? south["13"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-4-13"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["13"],
                            south["4"],
                            south["13"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 13,
                                loser: 4,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip right={205} top={340}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-1034.000000, -737.000000)">
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={480} right={0}>
                  {/* Rnd1 Five */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(south["6"], south["11"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>6</Seed>
                      <Name>{south["6"].team}</Name>
                      <Score>
                        {south["6"].score[1] > 0 ? south["6"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-6-11"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["6"],
                            south["11"],
                            south["6"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 6,
                                loser: 11,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>11</Seed>
                      <Name>{south["11"].team}</Name>
                      <Score>
                        {south["11"].score[1] > 0 ? south["11"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-6-11"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["11"],
                            south["6"],
                            south["11"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 11,
                                loser: 6,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines right={205} top={495}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-1034.000000, -737.000000)">
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
                <PlayPod top={596} right={0}>
                  {/* Rnd1 Six */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(south["3"], south["14"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>3</Seed>
                      <Name>{south["3"].team}</Name>
                      <Score>
                        {south["3"].score[1] > 0 ? south["3"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-3-14"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["3"],
                            south["14"],
                            south["3"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 3,
                                loser: 14,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>14</Seed>
                      <Name>{south["14"].team}</Name>
                      <Score>
                        {south["14"].score[1] > 0 ? south["14"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-3-14"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["14"],
                            south["3"],
                            south["14"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 14,
                                loser: 3,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip right={205} top={570}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-1034.000000, -737.000000)">
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={710} right={0}>
                  {/* Rnd1 Seven */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(south["7"], south["10"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>7</Seed>
                      <Name>{south["7"].team}</Name>
                      <Score>
                        {south["7"].score[1] > 0 ? south["7"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-7-10"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["7"],
                            south["10"],
                            south["7"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 7,
                                loser: 10,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>10</Seed>
                      <Name>{south["10"].team}</Name>
                      <Score>
                        {south["10"].score[1] > 0 ? south["10"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-7-10"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["10"],
                            south["7"],
                            south["10"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 10,
                                loser: 7,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines right={205} top={725}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-1034.000000, -737.000000)">
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
                <PlayPod top={825} right={0}>
                  {/* Rnd1 Eight */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(south["2"], south["15"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>2</Seed>
                      <Name>{south["2"].team}</Name>
                      <Score>
                        {south["2"].score[1] > 0 ? south["2"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-2-15"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["2"],
                            south["15"],
                            south["2"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 2,
                                loser: 15,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>15</Seed>
                      <Name>{south["15"].team}</Name>
                      <Score>
                        {south["15"].score[1] > 0 ? south["15"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"south-2-15"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            south["15"],
                            south["2"],
                            south["15"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "south",
                                winner: 15,
                                loser: 2,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip right={205} top={800}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-1034.000000, -737.000000)">
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLinesFlip>
              </Round>
              <Round>
                <PlayPod top={75} right={225}>
                  {/* Rnd2 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        south,
                        "1",
                        "16",
                        "8",
                        "9",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("1", "16", south, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(south["1"], south["16"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(south["1"], south["16"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "south-rnd2-1",
                          south,
                          "south",
                          "1",
                          "16",
                          "8",
                          "9"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("8", "9", south, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(south["8"], south["9"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(south["8"], south["9"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "south-rnd2-1",
                          south,
                          "south",
                          "8",
                          "9",
                          "1",
                          "16"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines right={430} top={90}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-1034.000000, -737.000000)">
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
                <PlayPod top={305} right={225}>
                  {/* Rnd2 Two */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        south,
                        "5",
                        "12",
                        "4",
                        "13",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("5", "12", south, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(south["5"], south["12"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(south["5"], south["12"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "south-rnd2-1",
                          south,
                          "south",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("4", "13", south, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(south["4"], south["13"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(south["4"], south["13"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "south-rnd2-1",
                          south,
                          "south",
                          "4",
                          "13",
                          "5",
                          "12"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip right={430} top={280}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-1034.000000, -737.000000)">
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={535} right={225}>
                  {/* Rnd2 Three */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        south,
                        "6",
                        "11",
                        "3",
                        "14",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("6", "11", south, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(south["6"], south["11"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(south["6"], south["11"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "south-rnd2-1",
                          south,
                          "south",
                          "6",
                          "11",
                          "3",
                          "14"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("3", "14", south, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(south["3"], south["14"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(south["3"], south["14"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "south-rnd2-1",
                          south,
                          "south",
                          "3",
                          "14",
                          "6",
                          "11"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines right={430} top={550}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-1034.000000, -737.000000)">
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
                <PlayPod top={765} right={225}>
                  {/* Rnd2 Four */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        south,
                        "7",
                        "10",
                        "2",
                        "15",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("7", "10", south, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(south["7"], south["10"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(south["7"], south["10"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "south-rnd2-1",
                          south,
                          "south",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("2", "15", south, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(south["2"], south["15"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(south["2"], south["15"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "south-rnd2-1",
                          south,
                          "south",
                          "2",
                          "15",
                          "7",
                          "10"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip right={430} top={740}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-1034.000000, -737.000000)">
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLinesFlip>
              </Round>
              <Round>
                <PlayPod top={190} right={310}>
                  {/* Rnd3 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxWinnerRnd3(
                        south,
                        "1",
                        "16",
                        "8",
                        "9",
                        "5",
                        "12",
                        "4",
                        "13"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(south, "1", "16", "8", "9")}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(south, "1", "16", "8", "9")}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(south, "1", "16", "8", "9")}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "south-rnd3-1",
                          south,
                          "south",
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(south, "5", "12", "4", "13")}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(south, "5", "12", "4", "13")}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(south, "5", "12", "4", "13")}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "south-rnd3-1",
                          south,
                          "south",
                          "5",
                          "12",
                          "4",
                          "13",
                          "1",
                          "16",
                          "8",
                          "9"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines left={34} top={240} height={200}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-1034.000000, -737.000000)">
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
                <PlayPod top={650} right={310}>
                  {/* Rnd3 Two */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxWinnerRnd3(
                        south,
                        "6",
                        "11",
                        "3",
                        "14",
                        "7",
                        "10",
                        "2",
                        "15"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(south, "6", "11", "3", "14")}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(south, "6", "11", "3", "14")}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(south, "6", "11", "3", "14")}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "south-rnd3-1",
                          south,
                          "south",
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(south, "7", "10", "2", "15")}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(south, "7", "10", "2", "15")}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(south, "7", "10", "2", "15")}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "south-rnd3-1",
                          south,
                          "south",
                          "7",
                          "10",
                          "2",
                          "15",
                          "6",
                          "11",
                          "3",
                          "14"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip left={34} bottom={210} height={200}>
                  <SVG viewBox="0 0 45 750">
                    <G
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLinesFlip>
              </Round>
              <Round>
                <PlayPod top={420} right={360}>
                  {/* Rnd4 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxWinnerRnd4(
                        south,
                        "1",
                        "16",
                        "8",
                        "9",
                        "5",
                        "12",
                        "4",
                        "13",
                        "6",
                        "11",
                        "3",
                        "14",
                        "7",
                        "10",
                        "2",
                        "15"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd4(
                          south,
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd4(
                          south,
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd4(
                          south,
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Score>
                      <Check>
                        {renderRnd4Select(
                          "south-rnd4-1",
                          south,
                          "south",
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13",
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd4(
                          south,
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd4(
                          south,
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd4(
                          south,
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Score>
                      <Check>
                        {renderRnd4Select(
                          "south-rnd4-1",
                          south,
                          "south",
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15",
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines left={4} height={400} top={475}>
                  <SVG viewBox="0 0 45 750">
                    <G transform="translate(-1034.000000, -737.000000)">
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </G>
                  </SVG>
                </BracketLines>
              </Round>
            </Region>
          </Regions>
          <BracketLogoHolder>
            <Image
              fluid={CenterLogo}
              alt={CenterLogoalt}
              imgStyle={{ objectFit: "contain" }}
            />
          </BracketLogoHolder>
          <CenterFinal>
            <CenterFinalRound>
              <ChampRoundTitle left={200}>
                <h3>RND OF FOUR</h3>
                <small>East/West</small>
              </ChampRoundTitle>
              <ChampTitle>
                <h3>Championship</h3>
                <small></small>
              </ChampTitle>
              <ChampRoundTitle right={200}>
                <h3>RND OF FOUR</h3>
                <small>South/Midwest</small>
              </ChampRoundTitle>
            </CenterFinalRound>
            <CenterFinalGames>
              <PlayPod left={192}>
                <PodStatus>
                  <PodState>{renderDeduxWinnerFinalFour(east, west)}</PodState>
                </PodStatus>
                <Teams>
                  <Team>
                    <Seed>{renderDeduxSeedFinalFour(east)}</Seed>
                    <Name>{renderDeduxTeamFinalFour(east)}</Name>
                    <Score>{renderDeduxScoreFinalFour(east)}</Score>
                    <Check>
                      {renderFinalFourSelect(
                        "ff-east-west",
                        east,
                        west,
                        "east",
                        "west"
                      )}
                    </Check>
                  </Team>
                  <Team>
                    <Seed>{renderDeduxSeedFinalFour(west)}</Seed>
                    <Name>{renderDeduxTeamFinalFour(west)}</Name>
                    <Score>{renderDeduxScoreFinalFour(west)}</Score>
                    <Check>
                      {renderFinalFourSelect(
                        "ff-east-west",
                        west,
                        east,
                        "west",
                        "east"
                      )}
                    </Check>
                  </Team>
                </Teams>
              </PlayPod>
              <PlayPodCenter>
                <PodStatus>
                  <PodState>
                    {renderDeduxWinnerChampionship(east, west, south, midwest)}
                  </PodState>
                </PodStatus>
                <Teams>
                  <Team>
                    <Seed>{renderDeduxSeedChampionship(east, west)}</Seed>
                    <Name>{renderDeduxTeamChampionship(east, west)}</Name>
                    <Score>{renderDeduxScoreChampionship(east, west)}</Score>
                    <Check>
                      {renderDeduxChampionshipSelect(
                        "champ-e-w-s-mw",
                        east,
                        "east",
                        west,
                        "west",
                        south,
                        "south",
                        midwest,
                        "midwest"
                      )}
                    </Check>
                  </Team>
                  <Team>
                    <Seed>{renderDeduxSeedChampionship(south, midwest)}</Seed>
                    <Name>{renderDeduxTeamChampionship(south, midwest)}</Name>
                    <Score>
                      {renderDeduxScoreChampionship(south, midwest)}
                    </Score>
                    <Check>
                      {renderDeduxChampionshipSelect(
                        "champ-e-w-s-mw",
                        south,
                        "south",
                        midwest,
                        "midwest",
                        east,
                        "east",
                        west,
                        "west"
                      )}
                    </Check>
                  </Team>
                </Teams>
              </PlayPodCenter>
              <PlayPod right={192} top={0}>
                <PodStatus>
                  <PodState>
                    {renderDeduxWinnerFinalFour(south, midwest)}
                  </PodState>
                </PodStatus>
                <Teams>
                  <Team>
                    <Seed>{renderDeduxSeedFinalFour(south)}</Seed>
                    <Name>{renderDeduxTeamFinalFour(south)}</Name>
                    <Score>{renderDeduxScoreFinalFour(south)}</Score>
                    <Check>
                      {renderFinalFourSelect(
                        "ff-south-midwest",
                        south,
                        midwest,
                        "south",
                        "midwest"
                      )}
                    </Check>
                  </Team>
                  <Team>
                    <Seed>{renderDeduxSeedFinalFour(midwest)}</Seed>
                    <Name>{renderDeduxTeamFinalFour(midwest)}</Name>
                    <Score>{renderDeduxScoreFinalFour(midwest)}</Score>
                    <Check>
                      {renderFinalFourSelect(
                        "ff-south-midwest",
                        midwest,
                        south,
                        "midwest",
                        "south"
                      )}
                    </Check>
                  </Team>
                </Teams>
              </PlayPod>
            </CenterFinalGames>
            <CenterFinalButtonContainer>
              {renderSubmit()}
            </CenterFinalButtonContainer>
          </CenterFinal>
          {/* <-------------------------------------------> */}
          <Regions>
            <Region>
              <RegionName>West</RegionName>
              <Round>
                <PlayPod top={20} left={0}>
                  {/* Rnd1 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(west["1"], west["16"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>1</Seed>
                      <Name>{west["1"].team}</Name>
                      <Score>
                        {west["1"].score[1] > 0 ? west["1"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-1-16"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["1"],
                            west["16"],
                            west["1"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 1,
                                loser: 16,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>16</Seed>
                      <Name>{west["16"].team}</Name>
                      <Score>
                        {west["16"].score[1] > 0 ? west["16"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-1-16"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["16"],
                            west["1"],
                            west["16"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 16,
                                loser: 1,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines left={205} top={35}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-372.000000, -741.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLines>
                <PlayPod top={135} left={0}>
                  {/* Rnd1 Two */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(west["8"], west["9"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>8</Seed>
                      <Name>{west["8"].team}</Name>
                      <Score>
                        {west["8"].score[1] > 0 ? west["8"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-8-9"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["8"],
                            west["9"],
                            west["8"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 8,
                                loser: 9,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>9</Seed>
                      <Name>{west["9"].team}</Name>
                      <Score>
                        {west["9"].score[1] > 0 ? west["9"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-8-9"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["9"],
                            west["8"],
                            west["9"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 9,
                                loser: 8,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip left={205} top={110}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-372.000000, -741.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={250} left={0}>
                  {/* Rnd1 Three */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(west["5"], west["12"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>5</Seed>
                      <Name>{west["5"].team}</Name>
                      <Score>
                        {west["5"].score[1] > 0 ? west["5"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-5-12"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["5"],
                            west["12"],
                            west["5"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 5,
                                loser: 12,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>12</Seed>
                      <Name>{west["12"].team}</Name>
                      <Score>
                        {west["12"].score[1] > 0 ? west["12"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-5-12"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["12"],
                            west["5"],
                            west["12"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 12,
                                loser: 5,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines left={205} top={265}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-372.000000, -741.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLines>
                <PlayPod top={365} left={0}>
                  {/* Rnd1 Four */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(west["4"], west["13"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>4</Seed>
                      <Name>{west["4"].team}</Name>
                      <Score>
                        {west["4"].score[1] > 0 ? west["4"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-4-13"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["4"],
                            west["13"],
                            west["4"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 4,
                                loser: 13,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>13</Seed>
                      <Name>{west["13"].team}</Name>
                      <Score>
                        {west["13"].score[1] > 0 ? west["13"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-4-13"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["13"],
                            west["4"],
                            west["13"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 13,
                                loser: 4,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip left={205} top={340}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-372.000000, -741.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={480} left={0}>
                  {/* Rnd1 Five */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(west["6"], west["11"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>6</Seed>
                      <Name>{west["6"].team}</Name>
                      <Score>
                        {west["6"].score[1] > 0 ? west["6"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-6-11"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["6"],
                            west["11"],
                            west["6"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 6,
                                loser: 11,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>11</Seed>
                      <Name>{west["11"].team}</Name>
                      <Score>
                        {west["11"].score[1] > 0 ? west["11"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-6-11"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["11"],
                            west["6"],
                            west["11"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 11,
                                loser: 6,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines left={205} top={495}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-372.000000, -741.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLines>
                <PlayPod top={595} left={0}>
                  {/* Rnd1 Six */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(west["3"], west["14"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>3</Seed>
                      <Name>{west["3"].team}</Name>
                      <Score>
                        {west["3"].score[1] > 0 ? west["3"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-3-14"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["3"],
                            west["14"],
                            west["3"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 3,
                                loser: 14,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>14</Seed>
                      <Name>{west["14"].team}</Name>
                      <Score>
                        {west["14"].score[1] > 0 ? west["14"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-3-14"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["14"],
                            west["3"],
                            west["14"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 14,
                                loser: 3,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip left={205} top={570}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-372.000000, -741.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={710} left={0}>
                  {/* Rnd1 Seven */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(west["7"], west["10"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>7</Seed>
                      <Name>{west["7"].team}</Name>
                      <Score>
                        {west["7"].score[1] > 0 ? west["7"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-7-10"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["7"],
                            west["10"],
                            west["7"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 7,
                                loser: 10,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>10</Seed>
                      <Name>{west["10"].team}</Name>
                      <Score>
                        {west["10"].score[1] > 0 ? west["10"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-7-10"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["10"],
                            west["7"],
                            west["10"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 10,
                                loser: 7,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines left={205} top={725}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-372.000000, -741.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLines>
                <PlayPod top={825} left={0}>
                  {/* Rnd1 Eight */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(west["2"], west["15"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>2</Seed>
                      <Name>{west["2"].team}</Name>
                      <Score>
                        {west["2"].score[1] > 0 ? west["2"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-2-15"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["2"],
                            west["15"],
                            west["2"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 2,
                                loser: 15,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>15</Seed>
                      <Name>{west["15"].team}</Name>
                      <Score>
                        {west["15"].score[1] > 0 ? west["15"].score[1] : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"west-2-15"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            west["15"],
                            west["2"],
                            west["15"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "west",
                                winner: 15,
                                loser: 2,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip left={205} top={800}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-372.000000, -741.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
              </Round>
              <Round>
                <PlayPod top={75} left={225}>
                  {/* Rnd2 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        west,
                        "1",
                        "16",
                        "8",
                        "9",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("1", "16", west, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(west["1"], west["16"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(west["1"], west["16"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "west-rnd2-1",
                          west,
                          "west",
                          "1",
                          "16",
                          "8",
                          "9"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("8", "9", west, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(west["8"], west["9"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(west["8"], west["9"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "west-rnd2-1",
                          west,
                          "west",
                          "8",
                          "9",
                          "1",
                          "16"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines left={430} top={90}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-372.000000, -741.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLines>
                <PlayPod top={305} left={225}>
                  {/* Rnd2 Two */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        west,
                        "5",
                        "12",
                        "4",
                        "13",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("5", "12", west, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(west["5"], west["12"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(west["5"], west["12"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "west-rnd2-1",
                          west,
                          "west",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("4", "13", west, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(west["4"], west["13"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(west["4"], west["13"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "west-rnd2-1",
                          west,
                          "west",
                          "4",
                          "13",
                          "5",
                          "12"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip left={430} top={280}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-372.000000, -741.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={535} left={225}>
                  {/* Rnd2 Three */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        west,
                        "6",
                        "11",
                        "3",
                        "14",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("6", "11", west, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(west["6"], west["11"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(west["6"], west["11"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "west-rnd2-1",
                          west,
                          "west",
                          "6",
                          "11",
                          "3",
                          "14"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("3", "14", west, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(west["3"], west["14"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(west["3"], west["14"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "west-rnd2-1",
                          west,
                          "west",
                          "3",
                          "14",
                          "6",
                          "11"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines left={430} top={550}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-372.000000, -741.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLines>
                <PlayPod top={765} left={225}>
                  {/* Rnd2 Four */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        west,
                        "7",
                        "10",
                        "2",
                        "15",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("7", "10", west, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(west["7"], west["10"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(west["7"], west["10"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "west-rnd2-1",
                          west,
                          "west",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("2", "15", west, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(west["2"], west["15"], "isElimOne")}
                      </Name>
                      <Score>
                        {renderDeduxScore(west["2"], west["15"], "isElimOne")}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "west-rnd2-1",
                          west,
                          "west",
                          "2",
                          "15",
                          "7",
                          "10"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip left={430} top={740}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-372.000000, -741.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
              </Round>
              <Round>
                <PlayPod top={190} left={310}>
                  {/* Rnd3 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxWinnerRnd3(
                        west,
                        "1",
                        "16",
                        "8",
                        "9",
                        "5",
                        "12",
                        "4",
                        "13"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(west, "1", "16", "8", "9")}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(west, "1", "16", "8", "9")}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(west, "1", "16", "8", "9")}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "west-rnd3-1",
                          west,
                          "west",
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(west, "5", "12", "4", "13")}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(west, "5", "12", "4", "13")}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(west, "5", "12", "4", "13")}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "west-rnd3-1",
                          west,
                          "west",
                          "5",
                          "12",
                          "4",
                          "13",
                          "1",
                          "16",
                          "8",
                          "9"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines right={34} top={240} height={200}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-372.000000, -741.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLines>
                <PlayPod top={650} left={310}>
                  {/* Rnd3 Two */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxWinnerRnd3(
                        west,
                        "6",
                        "11",
                        "3",
                        "14",
                        "7",
                        "10",
                        "2",
                        "15"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(west, "6", "11", "3", "14")}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(west, "6", "11", "3", "14")}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(west, "6", "11", "3", "14")}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "west-rnd3-1",
                          west,
                          "west",
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(west, "7", "10", "2", "15")}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(west, "7", "10", "2", "15")}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(west, "7", "10", "2", "15")}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "west-rnd3-1",
                          west,
                          "west",
                          "7",
                          "10",
                          "2",
                          "15",
                          "6",
                          "11",
                          "3",
                          "14"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip right={34} bottom={210} height={200}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-372.000000, -741.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
              </Round>
              <Round>
                <PlayPod top={420} left={360}>
                  {/* Rnd4 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxWinnerRnd4(
                        west,
                        "1",
                        "16",
                        "8",
                        "9",
                        "5",
                        "12",
                        "4",
                        "13",
                        "6",
                        "11",
                        "3",
                        "14",
                        "7",
                        "10",
                        "2",
                        "15"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd4(
                          west,
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd4(
                          west,
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd4(
                          west,
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Score>
                      <Check>
                        {renderRnd4Select(
                          "west-rnd4-1",
                          west,
                          "west",
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13",
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd4(
                          west,
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd4(
                          west,
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd4(
                          west,
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Score>
                      <Check>
                        {renderRnd4Select(
                          "west-rnd4-1",
                          west,
                          "west",
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15",
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip right={4} bottom={435} height={400}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-372.000000, -741.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(373.000000, 741.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
              </Round>
            </Region>
            <Region>
              <RegionName>Midwest</RegionName>
              <Round>
                <PlayPod top={20} right={0}>
                  {/* Rnd1 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(
                        midwest["1"],
                        midwest["16"],
                        "isElimOne"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>1</Seed>
                      <Name>{midwest["1"].team}</Name>
                      <Score>
                        {midwest["1"].score[1] > 0
                          ? midwest["1"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-1-16"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["1"],
                            midwest["16"],
                            midwest["1"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 1,
                                loser: 16,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>16</Seed>
                      <Name>{midwest["16"].team}</Name>
                      <Score>
                        {midwest["16"].score[1] > 0
                          ? midwest["16"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-1-16"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["16"],
                            midwest["1"],
                            midwest["16"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 16,
                                loser: 1,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines right={205} top={35}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLines>
                <PlayPod top={135} right={0}>
                  {/* Rnd1 Two */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(midwest["8"], midwest["9"], "isElimOne")}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>8</Seed>
                      <Name>{midwest["8"].team}</Name>
                      <Score>
                        {midwest["8"].score[1] > 0
                          ? midwest["8"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-8-9"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["8"],
                            midwest["9"],
                            midwest["8"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 8,
                                loser: 9,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>9</Seed>
                      <Name>{midwest["9"].team}</Name>
                      <Score>
                        {midwest["9"].score[1] > 0
                          ? midwest["9"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-8-9"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["9"],
                            midwest["8"],
                            midwest["9"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 9,
                                loser: 8,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip right={205} top={110}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={250} right={0}>
                  {/* Rnd1 Three */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(
                        midwest["5"],
                        midwest["12"],
                        "isElimOne"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>5</Seed>
                      <Name>{midwest["5"].team}</Name>
                      <Score>
                        {midwest["5"].score[1] > 0
                          ? midwest["5"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-5-12"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["5"],
                            midwest["12"],
                            midwest["5"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 5,
                                loser: 12,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>12</Seed>
                      <Name>{midwest["12"].team}</Name>
                      <Score>
                        {midwest["12"].score[1] > 0
                          ? midwest["12"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-5-12"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["12"],
                            midwest["5"],
                            midwest["12"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 12,
                                loser: 5,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines right={205} top={265}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLines>
                <PlayPod top={365} right={0}>
                  {/* Rnd1 Four */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(
                        midwest["4"],
                        midwest["13"],
                        "isElimOne"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>4</Seed>
                      <Name>{midwest["4"].team}</Name>
                      <Score>
                        {midwest["4"].score[1] > 0
                          ? midwest["4"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-4-13"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["4"],
                            midwest["13"],
                            midwest["4"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 4,
                                loser: 13,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>13</Seed>
                      <Name>{midwest["13"].team}</Name>
                      <Score>
                        {midwest["13"].score[1] > 0
                          ? midwest["13"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-4-13"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["13"],
                            midwest["4"],
                            midwest["13"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 13,
                                loser: 4,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip right={205} top={340}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={480} right={0}>
                  {/* Rnd1 Five */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(
                        midwest["6"],
                        midwest["11"],
                        "isElimOne"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>6</Seed>
                      <Name>{midwest["6"].team}</Name>
                      <Score>
                        {midwest["6"].score[1] > 0
                          ? midwest["6"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-6-11"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["6"],
                            midwest["11"],
                            midwest["6"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 6,
                                loser: 11,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>11</Seed>
                      <Name>{midwest["11"].team}</Name>
                      <Score>
                        {midwest["11"].score[1] > 0
                          ? midwest["11"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-6-11"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["11"],
                            midwest["6"],
                            midwest["11"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 11,
                                loser: 6,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines right={205} top={495}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLines>
                <PlayPod top={595} right={0}>
                  {/* Rnd1 Six */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(
                        midwest["3"],
                        midwest["14"],
                        "isElimOne"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>3</Seed>
                      <Name>{midwest["3"].team}</Name>
                      <Score>
                        {midwest["3"].score[1] > 0
                          ? midwest["3"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-3-14"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["3"],
                            midwest["14"],
                            midwest["3"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 3,
                                loser: 14,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>14</Seed>
                      <Name>{midwest["14"].team}</Name>
                      <Score>
                        {midwest["14"].score[1] > 0
                          ? midwest["14"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-3-14"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["14"],
                            midwest["3"],
                            midwest["14"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 14,
                                loser: 3,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip right={205} top={570}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={710} right={0}>
                  {/* Rnd1 Seven */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(
                        midwest["7"],
                        midwest["10"],
                        "isElimOne"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>7</Seed>
                      <Name>{midwest["7"].team}</Name>
                      <Score>
                        {midwest["7"].score[1] > 0
                          ? midwest["7"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-7-10"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["7"],
                            midwest["10"],
                            midwest["7"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 7,
                                loser: 10,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>10</Seed>
                      <Name>{midwest["10"].team}</Name>
                      <Score>
                        {midwest["10"].score[1] > 0
                          ? midwest["10"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-7-10"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["10"],
                            midwest["7"],
                            midwest["10"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 10,
                                loser: 7,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines right={205} top={725}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLines>
                <PlayPod top={825} right={0}>
                  {/* Rnd1 Eight */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeam(
                        midwest["2"],
                        midwest["15"],
                        "isElimOne"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>2</Seed>
                      <Name>{midwest["2"].team}</Name>
                      <Score>
                        {midwest["2"].score[1] > 0
                          ? midwest["2"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-2-15"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["2"],
                            midwest["15"],
                            midwest["2"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 2,
                                loser: 15,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                    <Team>
                      <Seed>15</Seed>
                      <Name>{midwest["15"].team}</Name>
                      <Score>
                        {midwest["15"].score[1] > 0
                          ? midwest["15"].score[1]
                          : null}
                      </Score>
                      <Check>
                        <RadioSelect
                          disabled={frozen}
                          name={"midwest-2-15"}
                          value={renderDeduxCheck(
                            "isElimOne",
                            midwest["15"],
                            midwest["2"],
                            midwest["15"]
                          )}
                          handleRadioChange={() =>
                            dispatch({
                              type: "isElimOne",
                              payload: {
                                region: "midwest",
                                winner: 15,
                                loser: 2,
                              },
                            })
                          }
                        />
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip right={205} top={800}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
              </Round>
              <Round>
                <PlayPod top={75} right={225}>
                  {/* Rnd2 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        midwest,
                        "1",
                        "16",
                        "8",
                        "9",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("1", "16", midwest, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(
                          midwest["1"],
                          midwest["16"],
                          "isElimOne"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScore(
                          midwest["1"],
                          midwest["16"],
                          "isElimOne"
                        )}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "midwest-rnd2-1",
                          midwest,
                          "midwest",
                          "1",
                          "16",
                          "8",
                          "9"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("8", "9", midwest, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(
                          midwest["8"],
                          midwest["9"],
                          "isElimOne"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScore(
                          midwest["8"],
                          midwest["9"],
                          "isElimOne"
                        )}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "midwest-rnd2-1",
                          midwest,
                          "midwest",
                          "8",
                          "9",
                          "1",
                          "16"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines right={430} top={90}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLines>
                <PlayPod top={305} right={225}>
                  {/* Rnd2 Two */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        midwest,
                        "5",
                        "12",
                        "4",
                        "13",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("5", "12", midwest, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(
                          midwest["5"],
                          midwest["12"],
                          "isElimOne"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScore(
                          midwest["5"],
                          midwest["12"],
                          "isElimOne"
                        )}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "midwest-rnd2-1",
                          midwest,
                          "midwest",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("4", "13", midwest, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(
                          midwest["4"],
                          midwest["13"],
                          "isElimOne"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScore(
                          midwest["4"],
                          midwest["13"],
                          "isElimOne"
                        )}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "midwest-rnd2-1",
                          midwest,
                          "midwest",
                          "4",
                          "13",
                          "5",
                          "12"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip right={430} top={280}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
                <PlayPod top={535} right={225}>
                  {/* Rnd2 Three */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        midwest,
                        "6",
                        "11",
                        "3",
                        "14",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("6", "11", midwest, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(
                          midwest["6"],
                          midwest["11"],
                          "isElimOne"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScore(
                          midwest["6"],
                          midwest["11"],
                          "isElimOne"
                        )}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "midwest-rnd2-1",
                          midwest,
                          "midwest",
                          "6",
                          "11",
                          "3",
                          "14"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("3", "14", midwest, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(
                          midwest["3"],
                          midwest["14"],
                          "isElimOne"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScore(
                          midwest["3"],
                          midwest["14"],
                          "isElimOne"
                        )}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "midwest-rnd2-1",
                          midwest,
                          "midwest",
                          "3",
                          "14",
                          "6",
                          "11"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines right={430} top={550}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLines>
                <PlayPod top={765} right={225}>
                  {/* Rnd2 Four */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxTeamRnd2(
                        midwest,
                        "7",
                        "10",
                        "2",
                        "15",
                        "isElimTwo"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("7", "10", midwest, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(
                          midwest["7"],
                          midwest["10"],
                          "isElimOne"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScore(
                          midwest["7"],
                          midwest["10"],
                          "isElimOne"
                        )}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "midwest-rnd2-1",
                          midwest,
                          "midwest",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeed("2", "15", midwest, "isElimOne")}
                      </Seed>
                      <Name>
                        {renderDeduxTeam(
                          midwest["2"],
                          midwest["15"],
                          "isElimOne"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScore(
                          midwest["2"],
                          midwest["15"],
                          "isElimOne"
                        )}
                      </Score>
                      <Check>
                        {renderRnd2Select(
                          "midwest-rnd2-1",
                          midwest,
                          "midwest",
                          "2",
                          "15",
                          "7",
                          "10"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip right={430} top={740}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
              </Round>
              <Round>
                <PlayPod top={190} right={310}>
                  {/* Rnd3 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxWinnerRnd3(
                        midwest,
                        "1",
                        "16",
                        "8",
                        "9",
                        "5",
                        "12",
                        "4",
                        "13"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(midwest, "1", "16", "8", "9")}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(midwest, "1", "16", "8", "9")}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(midwest, "1", "16", "8", "9")}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "midwest-rnd3-1",
                          midwest,
                          "midwest",
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(midwest, "5", "12", "4", "13")}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(midwest, "5", "12", "4", "13")}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(midwest, "5", "12", "4", "13")}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "midwest-rnd3-1",
                          midwest,
                          "midwest",
                          "5",
                          "12",
                          "4",
                          "13",
                          "1",
                          "16",
                          "8",
                          "9"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLines left={34} top={240} height={200}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLines>
                <PlayPod top={650} right={310}>
                  {/* Rnd3 Two */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxWinnerRnd3(
                        midwest,
                        "6",
                        "11",
                        "3",
                        "14",
                        "7",
                        "10",
                        "2",
                        "15"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(midwest, "6", "11", "3", "14")}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(midwest, "6", "11", "3", "14")}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(midwest, "6", "11", "3", "14")}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "midwest-rnd3-1",
                          midwest,
                          "midwest",
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd3(midwest, "7", "10", "2", "15")}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd3(midwest, "7", "10", "2", "15")}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd3(midwest, "7", "10", "2", "15")}
                      </Score>
                      <Check>
                        {renderRnd3Select(
                          "midwest-rnd3-1",
                          midwest,
                          "midwest",
                          "7",
                          "10",
                          "2",
                          "15",
                          "6",
                          "11",
                          "3",
                          "14"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip left={34} bottom={210} height={200}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
              </Round>
              <Round>
                <PlayPod top={420} right={360}>
                  {/* Rnd4 One */}
                  <PodStatus>
                    <PodState>
                      {renderDeduxWinnerRnd4(
                        midwest,
                        "1",
                        "16",
                        "8",
                        "9",
                        "5",
                        "12",
                        "4",
                        "13",
                        "6",
                        "11",
                        "3",
                        "14",
                        "7",
                        "10",
                        "2",
                        "15"
                      )}
                    </PodState>
                  </PodStatus>
                  <Teams>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd4(
                          midwest,
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd4(
                          midwest,
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd4(
                          midwest,
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Score>
                      <Check>
                        {renderRnd4Select(
                          "midwest-rnd4-1",
                          midwest,
                          "midwest",
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13",
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Check>
                    </Team>
                    <Team>
                      <Seed>
                        {renderDeduxSeedRnd4(
                          midwest,
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Seed>
                      <Name>
                        {renderDeduxTeamRnd4(
                          midwest,
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Name>
                      <Score>
                        {renderDeduxScoreRnd4(
                          midwest,
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15"
                        )}
                      </Score>
                      <Check>
                        {renderRnd4Select(
                          "midwest-rnd4-1",
                          midwest,
                          "midwest",
                          "6",
                          "11",
                          "3",
                          "14",
                          "7",
                          "10",
                          "2",
                          "15",
                          "1",
                          "16",
                          "8",
                          "9",
                          "5",
                          "12",
                          "4",
                          "13"
                        )}
                      </Check>
                    </Team>
                  </Teams>
                </PlayPod>
                <BracketLinesFlip left={4} bottom={435} height={400}>
                  <SVG viewBox="0 0 45 750">
                    <g
                      transform="translate(-1034.000000, -737.000000)"
                      stroke="#DBDCDE"
                      strokeWidth="2px"
                    >
                      <g transform="translate(1055.500000, 876.000000) scale(-1, 1) translate(-1055.500000, -876.000000) translate(1033.000000, 737.000000)">
                        <g>
                          <path d="M0,1 L43,1"></path>
                          <path d="M43.5,0.5 L43.5,750"></path>
                        </g>
                      </g>
                    </g>
                  </SVG>
                </BracketLinesFlip>
              </Round>
            </Region>
          </Regions>
        </Bracket>
      </InnerContainer>
    </BracketContainer>
  )
}

const MapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    bracket: state.auth.bracket,
  }
}

export default connect(MapStateToProps, actions)(MasterBracket)
