const InitalState = {
  seed: {
    region: "",
    seed: "",
    team: "",
    submit: "",
  },
  score: { region: "", seed: "", round: "", score: "", submit: "" },
}

const AdminErrorReducer = (state, action) => {
  const { type } = action
  switch (type) {
    case "seed":
      state[type][action.payload.target] = action.payload.error
      return { ...state }
    case "score":
      state[type][action.payload.target] = action.payload.error
      return { ...state }
    case "reset":
      return InitalState
    default:
      return state
  }
}

export default AdminErrorReducer
